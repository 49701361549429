/********************************************************************************* MIXINS */

/*  index

    ////// FONT MIXINS
    ////// FLEX
    ////// OTHER MIXINS
    ////// RESPONSIVE
    ////// FUNCTIONS
*/

/*
    ////// FONT MIXINS
*/

@mixin font-size($sizeValue) {
    font-size: ($sizeValue * 10) + px;
    font-size: $sizeValue + rem;
    line-height: ($sizeValue * 12.5) + px;
    line-height: ($sizeValue * 1.5) + rem;
}



/*
    ////// FLEX
*/
@mixin flex-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items:stretch;
    align-content: stretch;
    justify-content: space-between;
}
@mixin flex-flexible-element{
    flex: 1 0;
    min-width: 0;
}
@mixin flex-fixed-element($width){
    flex: 0 0 $width;
    min-width: 0;
}


/*
    ////// OTHER MIXINS
*/

@mixin alpha($color, $opacity: 0.3) {
    background: $color; 
    background: rgba($color, $opacity);
}
@mixin column($column-number, $column-width){
	-webkit-columns: $column-number $column-width;
	     -moz-columns: $column-number $column-width;
	          columns: $column-number $column-width;
}
@mixin color-link($color) {
    color: $color; 
    
    &:link, &:visited, &:active{
        color: $color; 
    }
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/*
    ////// RESPONSIVE
*/
@mixin bp($point) {

    $bp-minimobile: "(max-width: 400px)";
    $bp-mobile: "(max-width: 700px)";
    $bp-tablet: "(max-width: 900px)";
    $bp-desktop: "(max-width: 1200px)";
    $bp-superdesktop: "(min-width: 1201px)";

    @if $point == superdesktop {
        @media #{$bp-superdesktop} { @content; }
    }
    @else if $point == desktop {
        @media #{$bp-desktop} { @content; }
    }
    @else if $point == tablet {
        @media #{$bp-tablet}  { @content; }
    }
    @else if $point == mobile {
        @media #{$bp-mobile}  { @content; }
    }
    @else if $point == minimobile {
        @media #{$bp-minimobile}  { @content; }
    }
}


/*
    ////// FUNCTIONS

/*@each*/

/*
$list: 1 2 3 4 5 6 7 8 9 10;

@each $family-member in $list{
    .familiars-wrapper {
        .familiar-group {
            &:nth-child(#{$family-member}){
                &:after{
                    content:"#{$family-member}";
                }
            }
        }
    }
}*/