/********************************************************************************* ACCESS */

/*  index

    ////// COMMONS
    ////// LOGIN
    ////// REGISTER
    ////// CONFIRM EMAIL
    ////// THANK YOU PAGES


*/

/*
    ////// COMMONS
*/

.no-gender {
    background: #E20B0B;
    color: white;
    padding: 10px 20px; text-align: center; width: 80%; max-width: 1150px; margin: 5px auto; border-radius: 4px; position: relative; -webkit-animation: zoomIn 0.4s ease-in-out linear; animation: zoomIn 0.4s ease-in-out linear;
}

.no-gender strong {
    color: white;
}

#appleid-signin{
    width: 100%;
    height: 38px;
    border-radius:0;
    display: block;
}

.signin-button div:first-child {
    max-width: none !important;
}


/*
    ////// LOGIN
*/
.access-content, .register-and-subscription{
    background: $color-brown;
    min-height: 800px;
    padding: 40px;

    .registration-box{
        max-width: 430px;
        margin: 0 auto;
    }

    .registration-form-header{
        margin: 0 0 20px 0;
        text-align: center;

        h1{
            margin: 0;
        }
        h2{
            color: $color-third;
            @include font-size(2);
            font-weight: $regular;
        }
        a{
            @include color-link($color-orange);
            font-weight: $bold;
        }
    }
    input[type="text"], input[type="email"], input[type="password"]{
        border: none;
        border-bottom: 1px solid $color-third;
        padding: 5px 0 5px 30px;
        background: url("../img/icon-circle.svg") no-repeat left center;
    }
    label{
        font-weight: $bold;
        color: $color-third
    }
    .form-error, .error-message {
        @include font-size(1.4);
        color: $color-red;
    }
    input[type="submit"].main-button{
        display: block;
        width: 100%;
        margin: 20px 0;
        padding: 20px 30px;
    }

    @include bp(mobile){
        padding: 40px 20px;
    }
}
.social-register{
    max-width: 350px;
    margin: 10px auto 10px auto;

    .apple{
        margin: 0 0 10px 0;
    }
    .facebook-login{
        background: url("../img/icon-access-facebook.svg") no-repeat 20px center #fff;
        border-radius: 10px;
        border: 1px solid $color-brown;
        padding: 7px 20px 7px 50px;
        display: block;
        text-align: center;
        @include font-size(1.4);

        @include bp(mobile){
            background: url("../img/icon-access-facebook.svg") no-repeat 10px center #fff;
            @include font-size(1.2);
            padding: 7px 20px 7px 20px;
        }
    }
}
.register-form{
    margin: 30px 0;

    .error-message{
        margin: 10px 0;
        color: $color-red;
        @include font-size(1.2);
    }
}
.footer-registration-info{
    .forgot-password{
        text-align: center;
        @include font-size(1.4);

        a{
            @include color-link($color-third);
        }
    }
}


/*
    ////// REGISTER
*/

.register-data{
    background: #fff;
    border-radius: 40px;
    margin: 30px 0;
    padding: 40px;

    .genre-selection{
        p{
            @include font-size(1.4);
            color: $color-third;
        }
    }
    .genre{
        margin: 10px 0;

        li{
            display: inline-block;
            vertical-align: top;
            margin: 0 10px 0 0;
        }
    }
    @include bp(mobile){
        padding: 40px 20px;
    }
}
.or-separator{
    margin: 10px 0;
    position: relative;
    text-align: center;
    @include font-size(1.4);

    &:before, &:after{
        content: "";
        background: darken($color-brown,10%);
        position: absolute;
        height: 1px;
        width: 45%;
        top: 50%;
        transform: translateY(-50%);
    }
    &:before{
        left: 0;
    }

    &:after{
        right: 0;
    }
}
.accept-legal{
    margin: 10px 0;

    label{
        @include font-size(1.2);
        font-weight: $regular;
    }
}
.policy-hint{
    @include font-size(1.2);
    color: $color-third;

    a{
        @include color-link($color-orange);
    }
}
.already-user{
    margin: 0 0 10px 0;

    p{
        @include font-size(1.2);
        color: $color-red;

        a{
            text-decoration: underline;
        }
    }
}

/*
    ////// CONFIRM EMAIL
*/
.confirm-email-content{
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
}


/*
    ////// THANK YOU PAGES
*/
.generic-content{

    &.feedback-pages{
        padding: 0;
    }
}
.thank-you-content, .error-content{
    background: #fff;
    padding: 40px 100px;
    text-align: center;

    @include bp(tablet){
        padding: 40px 10px;
    }
}
.feedback-pages{
    .thank-you-content{
        padding: 0 100px;
    }
}
.error-content{
    h1{
        color: $color-red;
    }
    p{
        margin: 0 0 10px 0;
    }
}
.plans-features{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    margin: 40px auto;
    max-width: 900px;

    .registered-plan-features{
        .feature-title{
            background: $color-main;
            color: #fff;
        }
    }
    .subscribed-plan-features{
        .feature-title{
            background: $color-orange;
            color: #fff;
        }

        .main-button{
            background: $color-main;
        }
    }
    .feature-title{
        @include font-size(2.4);
        border-radius: 40px 40px 0 0;
        padding: 25px 40px;

        &.gopremium{
            background: $color-orange;
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            position: relative;

            &:before{
                content: url("../img/premium-star-current.svg");
                margin: 0 10px 0 0;
            }
        }
        @include bp(mobile){
            padding: 25px 10px;
            @include font-size(2.2);
        }
    }
    .main-button{
        display: block;
        margin: 20px 0;
        padding: 30px 40px;
        @include font-size(2.4);
    }
    ul{
        background: $color-brown;
        border-radius: 0 0 40px 40px;
        padding: 30px;

        li{
            margin: 0 0 7px 0;
            text-align: left;
            padding-left: 40px;

            &.included{
                background: url("../img/icon-tick-green.svg") no-repeat left 10px;
            }
            &.not-included{
                background: url("../img/icon-cross-red.svg") no-repeat left 10px;
            }
        }
        p{
            @include font-size(2.2);
            margin: 0;
            font-weight: $bold;

            &.hint{
                @include font-size(1.8);
                font-weight: $regular;
            }
        }
    }

    @include bp(tablet){
        grid-template-columns: repeat(1, 1fr);
    }
}
.functional-reminder{
    a{
        @include color-link($color-orange);
    }
}