/********************************************************************************* SIDEBAR */

/*  index

    ////// GLOBALS
    ////// SIDEBAR
*/

/*
    ////// GLOBALS
*/
.sidebar{
    background: $color-main;
}