/********************************************************************************* WHITE PAGES */

/*  index

    ////// COMMONS
    ////// TEXT STYLES

*/

/*
    ////// COMMONS
*/
.aside-title{
    @include font-size(2.4);
    border-radius: 40px 40px 0 0;
    padding: 25px 40px;
    background: $color-main;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
}

.aside-content{
    .registration-form{
        background: $color-brown;
        padding: 40px;
        border-radius: 0 0 40px 40px;

        .main-button{
            display: block;
            width: 100%;
        }
    }
    .register-data{
        background: none;
        padding: 0;

        input[type="text"], input[type="email"], input[type="password"]{
            border: none;
            border-bottom: 1px solid $color-third;
            padding: 5px 0 5px 30px;
            background: url("../img/icon-circle.svg") no-repeat left center;
        }
        label{
            font-weight: $bold;
            color: $color-third
        }
        input[type="submit"].main-button{
            display: block;
            width: 100%;
            margin: 20px 0;
            padding: 20px 30px;
        }
        .checkbox-group{
            a{
                @include color-link($color-orange);
            }
        }
    }
}

.main-wrapper.with-top-region{
    .top-region{
        position: relative;
        z-index: 10;
    }
    .aside-content{
        margin-top: -300px;
        position: relative;
        z-index: 100000;

        @include bp(desktop){
            margin-top: 0;
        }
    }
}
.brcm-body{
    h2{
        color: $color-orange;
        @include font-size(2);
    }

    h3{
        @include font-size(1.8);
        text-transform: uppercase;
    }
    ul{
        margin: 20px 0;
    }
    p{
        margin: 0 0 10px 0;

        a{
            @include color-link($color-orange);
        }
    }
}

.main-content{
    & > h1{
        @include font-size(3.5);
        text-transform: uppercase;
        margin: 0 0 10px 0;

        @include bp(tablet){
            @include font-size(3);
        }
    }
}

.teaser-video{
    overflow: hidden;
    height: 600px;
    max-height: 600px;
    height: 60vh;
    max-height: 60vh;
    border-top: 1px solid $color-main;
    border-bottom: 1px solid $color-main;

    video{
        width: 100%;
        background: url(../img/ajax-loader.gif) no-repeat center 50px;
        display: block;
    }

    @include bp(desktop){
        height: 400px;
        max-height: 400px;
        height: 40vh;
        max-height: 40vh;
    }
}