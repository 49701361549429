/********************************************************************************* MONDIA */

/*  index

    ////// COMMONS
    ////// VODAFONE
    ////// MOVISTAR
*/

/*
    ////// COMMONS
*/
body.mondia-user{
    .footer-one{

        /*.column{
            &:last-child{
                display: none;
            }
        }
        a[href*="facebook"]{
            display: none;
        }
        a[href*="twitter"]{
            display: none;
        }
        a[href*="youtube"]{
            display: none;
        }
        a[href*="instagram"]{
            display: none;.movistar-cancel-pag
        }

        a[href*="tarifas"]{
            display: none;
        }*/
    }
    .registration-box{
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
    }
    .register-page-title{
        background: $color-secondary;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;

        p{
            color: $color-main;
        }

        &.empty{
            display: none;
        }
    }
    .register-title{
        text-align: center;
        text-transform: uppercase;
        border-bottom: 1px solid $color-main;
        margin: 0 0 20px 0;

        h2{
            margin: 0;

            @media screen and (max-width:768px){
                @include font-size(2.2);

            }
        }
    }
    .mondia-register{
        .register-page-title{
            h1{
                color: $color-green;
            }
        }
        .user-already-exists{
            padding: 0 20px;

            a{
                color: $color-orange;
                text-decoration: underline;
                font-weight: $bold;
            }
        }
    }
    /*.movistar-claim{
        @include font-size(1.8);
        font-weight: $bold;
    }*/
    /*.top-menu{
        .claim{
            @include font-size(1.4);

            @media screen and (min-width: 800px){
                display: none;
            }
        }
    }*/
}
.cancel-feedback{
    text-align: center;
}



/*
    ////// VODAFONE
*/
.mondia{
    &.flow-vodafone{
        &.operator-logo{
            @include flex-fixed-element(90px);
            text-align: right;
        }
    }
}
.footer-vodafone{
    text-align: center;

    ul{
        margin: 0 auto;
    }
    li{
        display: inline-block;
        vertical-align: top;
        margin: 0 7px;
    }
    a{
        text-decoration: underline;
    }

    @media screen and (max-width:768px){
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
    }
}
.current-subscription-status{
    .vodafone-cancel{
        max-width: 600px;
        margin: 0 auto;

        li{
            background: none;
            display: block;
            color: $color-main;
            padding: 0;
            margin: 0;
        }

        @media screen and (max-width: 800px){
            max-width: none;
        }
    }
}
.vodafone-cancel-second-option{
    margin: 30px 0;
}
@media screen and (max-width: 600px){
    body.mondia-user{

        &.vodafone-user{
            .top-menu.public-menu{
                .logo{
                    @include flex-fixed-element(18%);
                    margin: 10px 0 0 0;
                }
            }
        }
    }
}

/*
    ////// MOVISTAR
*/
body.movistar-user{
    .cancel-feedback{
        text-align: left;
    }
}
.footer-movistar{
    text-align: center;

    ul{
        margin: 0 auto;
    }
    li{
        display: inline-block;
        vertical-align: top;
        margin: 0 7px;
    }
    a{
        text-decoration: underline;
    }

    @media screen and (max-width:768px){
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
    }
}


/*CANCEL*/
.current-subscription-status{
    .movistar-cancel, .yoigo-cancel, .orange-cancel{
        max-width: 600px;
        margin: 0 auto;

        li{
            background: none;
            display: block;
            color: $color-main;
            padding: 0;
            margin: 0;
        }

        @media screen and (max-width: 800px){
            max-width: none;
        }
    }
    .movistar-cancel, .yoigo-cancel, .orange-cancel{
        text-align: left;
    }
    .movistar-cancel-second-option, yoigo-cancel-second-option, orange-cancel-second-option{
        li{
            text-align: left;
        }
        a, a:link, a:visited, a:active{
            text-decoration: underline;
        }
    }
}
.main-content{
    .movistar-cancel-second-option, .yoigo-cancel-second-option, .orange-cancel-second-option{
        a, a:link, a:visited, a:active{
            text-decoration: underline;
        }
    }
}
.movistar-cancel-page, .yoigo-cancel-page, .orange-cancel-page{
    p, li{
        @include font-size(1.6);

    }
    li{
        padding: 0;
        margin: 0;
    }
    p{
        margin: 0;
    }
    a.main-button{
        margin: 10px 0;
    }
}

.movistar-cancel-second-option, .yoigo-cancel-second-option, .orange-cancel-second-option{
    margin: 30px 0;
}

.current-subscription-status.movistar-user, .current-subscription-status.yoigo-user, .current-subscription-status.orange-user{
    .subscription-cancel-info{
        background: white;
        padding: 0;
    }
}
.movistar-goto-login, .yoigo-goto-login, .orange-goto-login{

    &:link, &:visited, &:active{
        display: block;
        text-align: center;
        color: $color-orange;
        padding: 10px;
        text-decoration: underline;
    }
}


/*Header*/
body.subscribed{
    &.movistar-user, &.yoigo-user, &.orange-user{
        .logo{
            @media screen and (max-width: 800px){
                @include flex-fixed-element(55%);
            }
        }
    }
}

body.movistar-user .goals-menu .logo, body.yoigo-user .goals-menu .logo, body.orange-user .goals-menu .logo{
    @include flex-fixed-element(100%);

    img{
        max-width: 280px;
    }

    @media screen and (max-width: 560px){
        img{
            max-width: 280px;
            max-height: none;
        }
    }

    /*a{
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px 0 0;
        width: 150px;
    }
    .claim {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
    }

    @media screen and (max-width: 800px){

        a{
            width: 26%;
        }
        .claim{
            width: 70%;
            @include font-size(1.4);
            line-height: 14px;
            line-height: 1.4rem;
            font-style: italic;
            text-align: left;
        }
        img{
            max-width: 100%;
        }
    }*/

}
body.movistar-user, body.yoigo-user, body.orange-user{
    .top-menu.public-menu{
        .logo{
            @include flex-fixed-element(100%);
            padding: 0 10px 0 0;
            text-align: center;
            max-height: 45px;

            img{
                max-width: 280px;
            }

            @media screen and (max-width: 800px){
                width: 100%;
                @include flex-fixed-element(100%);
            }
            @media screen and (max-width: 560px){
                img{
                    max-width: 280px;
                    max-height: none;
                }
            }
        }

        /*.flow-movistar.operator-logo{
            display: inline-block;
            vertical-align: middle;
            @include font-size(1.4);
            line-height: 14px;
            line-height: 1.4rem;
            font-style: italic;
            text-align: left;
            margin: 0;

            p{
                @include font-size(1.6);
                line-height: 16px;
                line-height: 1.6rem;
                font-style: italic;
                text-align: left;
                margin: 10px 0 0 0;
            }

            @media screen and (max-width: 800px){
                width: 70%;
            }
        }*/
    }
}

/*MOVISTAR MY ACCOUNT MENU --> styles for Hambuer menu only for Movistar cases */
body.subscribed .user-menu ul.movistar-my-account-menu, body.registered .user-menu ul.movistar-my-account-menu{
    display: none;
}
.trigger-movistar-my-account-menu{
    display: none;
}



/*
    ////// YOIGO
*/
body.yoigo-user{
    .cancel-feedback{
        text-align: left;
    }
    .red_button{
        background: #F23436;
        padding: 10px 40px;
        @include font-size(2);
        text-transform: uppercase;
        font-weight: $bold;
        display: inline-block;
        border-radius: 7px;
    }
    .subscribed-content{
        padding-top: 40px;
    }
}
.footer-yoigo{
    text-align: center;

    ul{
        margin: 0 auto;
    }
    li{
        display: inline-block;
        vertical-align: top;
        margin: 0 7px;
    }
    a{
        text-decoration: underline;
    }

    @media screen and (max-width:768px){
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
    }
}

.yoigo-cancel{
    @media screen and (min-width: 801px){
        padding-left: 20px;
    }
}
.cancel-yoigo-subscription-process{
    text-align: center;
}
.yoigo-error-content{
    text-align: center;
}
.yoigo-error-content-buttons{
    li{
        display: inline-block;
        vertical-align: top;
        margin: 0 5px;
    }
}

/*YOIGO MY ACCOUNT MENU --> styles for Hambuer menu only for Yoigo cases */
body.subscribed .user-menu ul.yoigo-my-account-menu, body.registered .user-menu ul.yoigo-my-account-menu{
    display: none;
}
.trigger-yoigo-my-account-menu{
    display: none;
}

/* END YOIGO */


/*
    ////// ORANGE
*/
body.orange-user{
    .cancel-feedback{
        text-align: left;
    }
    .red_button{
        background: #F23436;
        padding: 10px 40px;
        @include font-size(2);
        text-transform: uppercase;
        font-weight: $bold;
        display: inline-block;
        border-radius: 7px;
    }
    .subscribed-content{
        padding-top: 40px;
    }
}
.footer-orange{
    text-align: center;

    ul{
        margin: 0 auto;
    }
    li{
        display: inline-block;
        vertical-align: top;
        margin: 0 7px;
    }
    a{
        text-decoration: underline;
    }

    @media screen and (max-width:768px){
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
    }
}

.orange-cancel{
    @media screen and (min-width: 801px){
        padding-left: 20px;
    }
}
.cancel-orange-subscription-process{
    text-align: center;
}
.orange-error-content{
    text-align: center;
}
.orange-error-content-buttons{
    li{
        display: inline-block;
        vertical-align: top;
        margin: 0 5px;
    }
}

/*ORANGE MY ACCOUNT MENU --> styles for Hambuer menu only for ORANGE cases */
body.subscribed .user-menu ul.orange-my-account-menu, body.registered .user-menu ul.orange-my-account-menu{
    display: none;
}
.trigger-orange-my-account-menu{
    display: none;
}

/* END YOIGO */

/*
    ////// MEDIA QUERIES
*/

@media screen and (max-width: 800px){
    .trigger-movistar-my-account-menu, .trigger-yoigo-my-account-menu, .trigger-orange-my-account-menu{
        display: block;
    }
    body.subscribed .user-menu ul.movistar-my-account-menu, body.registered .user-menu ul.movistar-my-account-menu,
    body.subscribed .user-menu ul.yoigo-my-account-menu, body.registered .user-menu ul.yoigo-my-account-menu,
    body.subscribed .user-menu ul.orange-my-account-menu, body.registered .user-menu ul.orange-my-account-menu{
        display: none;

        li{
            display: block;
            padding-left: 20px;
        }
    }
    body.subscribed.movistar-user .logo.movistar-logo, body.registered.movistar-user .logo.movistar-logo,
    body.subscribed.yoigo-user .logo.yoigo-logo, body.registered.yoigo-user .logo.yoigo-logo,
    body.subscribed.orange-user .logo.orange-logo, body.registered.orange-user .logo.orange-logo{
        @include flex-fixed-element(82%);
        text-align: left;
        margin: 0;

        a{
            display: block;
        }
        img{
            width: auto;
            max-height: 80px;
            max-width: 100%;
        }
    }
}


@media screen and (max-width: 800px){
    .current-subscription-status{
        &.movistar-user, &.yoigo-user, , &.orange-user{
            .subscription-remaining-days, .subscription-ending{
                display: none;
            }
        }
    }
}
@media screen and (max-width: 600px){
    body.mondia-user{
        .goals-form-page{
            padding: 10px 20px;
        }
    }
    body.subscribed.movistar-user{
        padding-top: 40px;
    }
    body.subscribed.yoigo-user{
        padding-top: 70px;
    }
    body.subscribed.orange-user{
        padding-top: 70px;
    }
}


@media screen and (max-width: 560px){
    body.mondia-user{
        .main-wrapper.generic-content{
            padding: 0;
        }
        /*.top-menu.public-menu{
            .logo{
                @include flex-fixed-element(40%);
                text-align: left;
            }
        }*/
    }
}

/**** end MONDIA ****/