/********************************************************************************* LIVE */

/*  index

    ////// HOME LIVES
    ////// LIVE DETAIL

*/

/*
    ////// HOME LIVES
*/
.lives{
    @include flex-container;
}
.no-live-video{
    text-align: center;
    @include font-size(1.4);
    margin: 20px 0;
    width: 100%;
    color: $color-red;
}
.live-next-session{
    @include flex-fixed-element(48%);
    background: $color-main;
    border-radius: 40px;
    padding: 30px;
    color: #fff;

    h2{
        @include font-size(1.6);
        font-weight: $regular;
        margin: 0 0 10px 0;

        .live-next-session-title{
            display: inline-block;
            vertical-align: top;
        }

        .live-more-info{
            display: inline-block;
            vertical-align: top;
            float: right;

            a{
                @include color-link($color-orange);
            }
        }
    }

    .no-live-video{
        color: $color-red;
    }
    .live-next-session-info{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;

        @include bp(minimobile){
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .live-next-session-title{
        h3{
            @include font-size(2.4);
            font-weight: $regular;

            a{
                @include color-link(#fff);
            }
        }
        p{
            @include font-size(1.4);
        }
    }
    .live-next-session-time{
        text-align: right;
    }

    @include bp(tablet){
        @include flex-fixed-element(100%);
        margin: 0 0 20px 0;
        order: 2;
    }
}
.live-date-title, .live-hour-title{
    @include font-size(1.1);
}
.live-date-value, .live-hour-value{
    @include font-size(2.6);
    color: $color-orange;
}
.live-current-session{
    @include flex-fixed-element(48%);
    background: $color-red;
    border-radius: 40px;
    padding: 30px;
    color: #fff;

    h2{
        @include font-size(1.6);
        font-weight: $regular;
        margin: 0 0 10px 0;
        background: url("../img/icon-circle-white.svg") no-repeat left center;
        padding-left: 25px;
    }

    .no-live-video{
        color: #fff;
    }

    .live-current-session-info{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
    }
    .live-current-session-access{
        text-align: right;
    }
    .live-current-session-title{
        h3{
            @include font-size(2.4);
            font-weight: $regular;
        }
        p{
            @include font-size(1.4);
        }
    }
    .live-current-session-subscribe{
        text-align: center;
        font-weight: $bold;

        a{
            @include color-link(#fff);
            text-decoration: underline;
        }
    }

    @include bp(tablet){
        @include flex-fixed-element(100%);
        margin: 0 0 20px 0;
        order: 1;
    }
}
.not-subscribed-live-message{
    @include flex-fixed-element(48%);
    background: url("../img/premium-star.svg") no-repeat left 5px;
    padding-left: 30px;

    a{
        @include color-link($color-orange);
        text-decoration: underline;
    }
}
.live-week-toolbar{
    @include flex-fixed-element(100%);
    margin: 30px 0 0 0;

    .switch-week{
        border: 1px solid $color-orange;
        text-transform: none;
        display: inline-block;
        border-radius: 40px;
        background: #fff;
        @include color-link($color-orange);
        padding: 10px 30px;

        &:hover, &:active{
            color: darken($color-orange, 10%);
        }

    }
}
.live-calendar{
    @include flex-fixed-element(100%);
    margin: 0 0 40px 0;
    background: #fff;
    border-radius: 40px;

    @include bp(tablet){
        background: none;
        padding: 0;
        order: 3;
    }
}
.live-calendar-header, .live-calendar-week{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px;
    text-align: center;
    padding: 20px 10px;
}
.live-calendar-header{
    @include bp(tablet){
        display: none;
    }
}

.live-calendar-header{
    background: $color-main;
    color: $color-orange;
    padding: 20px 10px;
    border-radius: 40px 40px 0 0;
}
.live-calendar-week{
    grid-template-areas: 'lunes martes miercoles jueves viernes sabado domingo';

    li{
        @include bp(tablet){
            background: #fff;
            border-radius: 40px;

            &:before{
                background: $color-main;
                color: $color-orange;
                padding: 20px 10px;
                border-radius: 40px 40px 0 0;
                display: block;
                margin: 0 0 20px 0;
            }
        }
        &.day-1{
            grid-area: lunes;

            @include bp(tablet){
                &:before{
                    content: 'Lunes';
                }
            }
        }
        &.day-2{
            grid-area: martes;

            @include bp(tablet){
                &:before{
                    content: 'Martes';
                }
            }
        }
        &.day-3{
            grid-area: miercoles;

            @include bp(tablet){
                &:before{
                    content: 'Miércoles';
                }
            }
        }
        &.day-4{
            grid-area: jueves;

            @include bp(tablet){
                &:before{
                    content: 'Jueves';
                }
            }
        }
        &.day-5{
            grid-area: viernes;

            @include bp(tablet){
                &:before{
                    content: 'Viernes';
                }
            }
        }
        &.day-6{
            grid-area: sabado;

            @include bp(tablet){
                &:before{
                    content: 'Sábado';
                }
            }
        }
        &.day-7{
            grid-area: domingo;

            @include bp(tablet){
                &:before{
                    content: 'Domingo';
                }
            }
        }

        a{
            background: $color-orange;
            padding: 20px 10px;
            display: block;
            @include color-link(#fff);
            margin: 0 0 20px 0;
            border-radius: 20px;

            &:hover{
                background: darken($color-orange,10%);
            }
            /*&.not-subscribed{
                pointer-events: none;
            }*/

            &.done{
                opacity: 0.5;
            }

            @include bp(tablet){
                margin: 0 20px 20px 20px;
            }
        }
    }
    .live-calendar-event-title{
        @include font-size(1.8);
        display: block;

        @include bp(desktop){
            @include font-size(1.6);
        }
    }
    .live-calendar-event-date{
        @include font-size(1.6);
        color: $color-main;
        display: block;

        @include bp(desktop){
            @include font-size(1.6);
        }
    }

    @include bp(tablet){
        grid-template-columns: repeat(1, 1fr);
        background: none;
        padding: 0;
        grid-template-areas:
                'lunes'
                'martes'
                'miercoles'
                'jueves'
                'viernes'
                'sabado'
                'domingo'
        ;
    }
    .reminder{
        @include font-size(1.2);
        border-radius: 20px;
        min-height: 94px;
        margin: 0 0 10px 0;

        a{
            background: none;
            @include color-link($color-orange);
            padding: 0;
            margin: 0;
            display: inline-block;

            &:hover{
                background: none;
                text-decoration: underline;
            }
        }

        @include bp(tablet){
            max-width: 90%;
            margin:20px auto;
        }
    }
}


/*
    ////// LIVE DETAIL
*/
.live-info{
    @include flex-container;
    background: #fff;
    border-radius: 40px;

    .live-detail-image{
        @include flex-fixed-element(100%);
        height: 250px;
        overflow: hidden;
        border-radius: 40px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .live-detail-info{
        @include flex-fixed-element(48%);
        padding: 40px;

        h1{
            color: $color-orange;
            @include font-size(2.4);
        }
        h2{
            @include font-size(2);
        }

        @include bp(tablet){
            @include flex-fixed-element(100%);
            padding: 20px 40px;
        }
    }
    .live-detail-date{
        @include flex-fixed-element(48%);
        padding: 40px;
        text-align: right;

        @include bp(tablet){
            @include flex-fixed-element(100%);
            text-align: left;
            padding: 20px 40px;
        }
    }

    .live-observations{
        margin: 20px 0;

        p{
            @include font-size(1.4);
        }
    }
    .live-detail-teacher{
        h2{
            @include font-size(2);
        }
    }
}
.live-video{
    display: block;
    width: 100%;
    min-height: 400px;

    @media screen and (max-width: 1800px){
        min-height: 1px;
    }
}
.live-cta{
    @include flex-fixed-element(100%);
    margin: 0 0 30px 0;
    text-align: center;
}

.live-detail{
    @include flex-container;

    .main-title{
        @include flex-fixed-element(100%);
    }
    .live-video{
        @include flex-fixed-element(55%);

        @media screen and (max-width: 1800px){
            @include flex-fixed-element(100%);
            margin: 0 0 30px 0;
        }

    }
    .live-chat{
        @include flex-fixed-element(560px);

        @media screen and (max-width: 1800px){
            @include flex-fixed-element(100%);
        }
    }

    #liveAlertMessage{
        display: none;
        //opacity: 0;
        @include flex-fixed-element(100%);
        background: url("../img/icon-alert.svg") no-repeat 20px center;
        padding: 10px 10px 10px 60px;
        color: $color-red;
        border: 1px solid $color-red;
        border-radius: 30px;
        margin: 0 0 10px 0;
        font-weight: $bold;
        min-height: 40px;

        /*animation: fadeIn;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-delay: 2s;
        animation-fill-mode: forwards;*/
    }
}