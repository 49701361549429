/********************************************************************************* SUBSCRIPTION */

/*  index

    ////// COMMONS
    ////// USER SUBSCRIPTION
    ////// PURCHASE LIST
    ////// SUBSCRIPTION FORM
    ////// SPECIFIC FOR SUBSCRIBED
    ////// SPECIFIC FOR REGISTERED
    ////// SUBSCRIPTION LANDING PREMIUM SIETE

*/

/*
    ////// COMMONS
*/



/*
    ////// USER SUBSCRIPTION
*/
.current-subscription-status{

    ul{
        display: grid;
        grid-template-columns: 1fr 2fr 3fr;

        @include bp(tablet){
            grid-template-columns: 1fr 1fr;
            grid-template:
                'days finish'
                'hint hint'
            ;
        }
        @include bp(mobile){
            grid-template-columns: 1fr;
            grid-template:
                    'days'
                    'finish'
                    'hint'
        ;
        }
    }
    li{
        padding: 30px;
        text-align: center;

        .highlighted-status-number{
            @include font-size(5);
            font-weight: $regular;
            display: block;
        }

        &:nth-child(1){
            background: $color-orange;
            color: #fff;
            border-radius: 50px 0 0 50px;

            @include bp(tablet){
                grid-area: days;
                border-radius: 40px 0 0 0;
            }
            @include bp(mobile){
                border-radius: 40px 40px 0 0;
            }
        }
        &:nth-child(2){
            background: $color-main;
            color: #fff;

            @include bp(tablet){
                grid-area: finish;
                border-radius: 0 40px 0 0;
            }
            @include bp(mobile){
                border-radius: 0;
            }
        }
        &:nth-child(3){
            background: #fff;
            border-radius: 0 50px 50px 0;

            @include bp(tablet){
                grid-area: hint;
                border-radius: 0 0 40px 40px;
            }
            @include bp(mobile){
                border-radius: 0 0 40px 40px;
            }
        }

        a{
            @include color-link($color-orange);
        }
        .main-button{
            @include color-link(#fff);
            margin: 20px 0;
        }
    }
    br{
        display: none;
    }
}

.cancel-automatic-payment{
    margin: 40px 0;

    h1{
        @include font-size(3);
        text-transform: uppercase;
        margin: 0 0 40px 0;

        @include bp(tablet){
            text-align: center;
        }
    }
}
.cancel-automatic-payment-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;

    @include bp(mobile){
        grid-template-columns: repeat(1, 1fr);
    }
}
.cancel-automatic-payment-option{
    padding: 30px 50px;
}
.cancel-automatic-payment-box{
    background: #fff;
    border-radius: 50px;
    text-align: center;

    h2{
        background: $color-main;
        color: #fff;
        border-radius: 50px 50px 0 0;
        padding: 20px;
        text-align: center;
        text-transform: uppercase;
        @include font-size(2);
        font-weight: $regular;
    }

    ol{
        text-align: left;
        margin: 20px 0;
    }

    &.tpv{
        h2{
            &:before{
                content: url("../img/payment-visa.png");
                margin: 0 10px 0 0;
                position: relative;
                top: 5px;
            }
        }
    }
    &.googleplay{
        h2{
            &:before{
                content: url("../img/payment-googleplay.png");
                margin: 0 10px 0 0;
                position: relative;
                top: 5px;
            }
        }
    }
    &.appstore{
        h2{
            &:before{
                content: url("../img/payment-appstore.png");
                margin: 0 10px 0 0;
                position: relative;
                top: 5px;
            }
        }
    }
    &.paypal{
        h2{
            &:before{
                content: url("../img/payment-paypal.png");
                margin: 0 10px 0 0;
                position: relative;
                top: 5px;
            }
        }
    }
}
.payment-status{
    @include flex-flexible-element;
    margin: 20px 0;

    li{
        input[type="radio"]{
            position: absolute;
            opacity: 0;
            z-index: 1;


            & + label{
                position: relative;
                z-index: 10;
                @include font-size(1.4);
                font-weight: $bold;
                color: $color-third;
                opacity: 0.3;
            }

            &:checked{
                & + label{
                    opacity: 1;
                }
            }
        }
    }
}

/*
    ////// PURCHASE LIST
*/
.bills{
    background: #fff;
    border-radius: 50px;
    padding: 20px 40px;
    margin: 30px 0;
}


/*
    ////// SUBSCRIPTION FORM
*/
.subscription-page{
    .generic-content{
        padding: 0;
    }
    /*.subscription{
        position: relative;
    }
    .choose-plan{
        position: absolute;
        top: 100px;
    }*/
    .choose-plan{
        margin: 30px 0;
    }
    .subscription{
        @include flex-container;

        .register-and-subscription{
            @include flex-fixed-element(60%);

            @include bp(desktop){
                @include flex-fixed-element(100%);
                order: 1;
            }

            &.logged-in {
                @include flex-fixed-element(100%);
            }
        }
    }
}
.top-subscription{
    background: url("../img/photo-vertical-yoga.jpg") no-repeat left top $color-brown;
    background-size: cover;
    padding: 40px;
    @include flex-fixed-element(40%);
    position: relative;

    /*.banner-promotion{
        max-width: $wrapper;
        margin: 0 auto;
        padding: 0 100px;
    }
    .registered-plan-features{
        padding-top: 300px;
    }*/
    .plans-features{
        display: block;
        margin: 0;
        max-width: 500px;
        position: absolute;
        right: 40px;
        top: 130px;
        //transform: translateY(-50%);


        ul{
            padding: 30px;

            @include bp(mobile){
                padding: 30px 20px;
            }
        }

        .main-button{
            display: none;
            pointer-events: none;
        }

        @include bp(desktop){
            position: relative;
            top: auto;
            right: auto;
            float: right;
            transform: none;
        }
        @include bp(mobile){
            float: none;
        }
    }

    @include bp(desktop){
        @include flex-fixed-element(100%);
        order: 2;
    }
    @include bp(mobile){
        padding: 40px 20px;
    }
}

.register-and-subscription{
    background: $color-brown;
    padding: 40px;

    .main-title{
        text-align: center;
    }

    .registration-form{
        background: #fff;
        border-radius: 40px;
        //margin: 30px auto;
        margin: 0 auto;
        padding: 40px;
        max-width: 900px;

        .register-data{
            background: none;
            border-radius: 0;
        }
        @include bp(mobile){
            padding: 40px 20px;
        }
    }
    .social-register{
        max-width: 600px;

        .apple{
            display: inline-block;
            vertical-align: top;
            width: auto;
            max-width: 48%;

            @include bp(mobile){
                width: 100%;
                max-width: none;
                display: block;
            }
        }
        .facebook-login{
            display: inline-block;
            vertical-align: top;

            @include bp(mobile){
                width: 100%;
                max-width: none;
                display: block;
            }
        }
    }
    .user-data{
        @include flex-container;

        .field-block{
            &:nth-child(1){
                @include flex-fixed-element(100%);
                text-align: center;
                margin: 0 0 10px 0;
            }
            &:nth-child(2){
                @include flex-fixed-element(48%);

                @include bp(mobile){
                    @include flex-fixed-element(100%);
                }
            }
            &:nth-child(3){
                @include flex-fixed-element(48%);

                @include bp(mobile){
                    @include flex-fixed-element(100%);
                }
            }
            &:nth-child(4){
                @include flex-fixed-element(48%);

                @include bp(mobile){
                    @include flex-fixed-element(100%);
                }
            }
            &:nth-child(5){
                @include flex-fixed-element(48%);

                @include bp(mobile){
                    @include flex-fixed-element(100%);
                }
            }
        }

        .genre{
            margin: 0;
        }
    }
    .register-data{
        margin-top: 0;
        padding-top: 0;
    }
    .already-user, .help-block, .no-plan-selected, .no-payment-type-selected{
        @include font-size(1.4);
        color: $color-red;

        a{
            @include color-link($color-red);
            text-decoration: underline;
        }
    }
    .no-plan-selected{
        margin: 10px 0 0 0;
    }
    .register-data{
        @include bp(tablet){
            padding: 0;
        }
    }
    @include bp(mobile){
        padding: 40px 20px;
    }
}

.choose-plan{
    .subscription-plans{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;

        li{
            &:nth-child(1){
                display: none;
            }

            input[type="radio"]{
                position: absolute;
                opacity: 0;
                z-index: 1;

                & + label{
                    border: 10px solid transparent;
                    width: 100%;
                }

                &:checked + label{
                    border: 10px solid $color-orange;
                    border-radius: 50px;
                }
            }
        }

        @include bp(tablet){
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 30px;
        }
    }
    .plan-info{
        color: $color-main;
        text-align: center;
        background: #fff;
        border-radius: 40px;
        position: relative;
        padding-bottom: 40px;

        .plan-title{
            background: $color-orange;
            padding: 6px 20px;
            color: #fff;
            text-transform: uppercase;
            @include font-size(2);
            margin: 0 auto;
            border-radius: 20px;
            position: absolute;
            width: 90%;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
        }
        .product-duration{
            background: $color-main;
            padding: 10px 20px;
            border-radius: 40px 40px 0 0;
            color: #fff;
            @include font-size(2.2);
            text-transform: uppercase;
        }
        .price{
            /*@include font-size(8);
            line-height: 60px;
            line-height: 6rem;*/
            @include font-size(4);
            padding: 5px 20px;
        }
        .plan-discount{
            @include font-size(1.8);
            color: $color-dark-gray;
            font-weight: $regular;
        }
    }
}

.money-choice{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: 20px 0;

    @include bp(tablet){
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 30px;
    }
}
.currency-choice{
    .currency{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 5px;
        margin: 7px 0;
        max-width: 200px;

        input[type="radio"]{
            position: absolute;
            opacity: 0;
            z-index: 1;

            & + label{
                background: $color-main;
                border-radius: 10px;
                @include font-size(1.4);
                font-weight: $regular;
                text-transform: uppercase;
                color: #fff;
                padding: 5px 10px;
                width: 100%;
                text-align: center;
            }

            &:checked + label{
                background: $color-orange;
            }
        }
    }
}
.payment-method{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: 7px 0;
}

.promo-code {
    margin: 30px 0;

    input {
        display: inline-block;
        vertical-align: middle;
        width: 48%;

        @include bp(mobile){
            display: block;
            width: 100%;
            margin: 0 0 10px 0;
        }
    }
    #validate-promo-code {
        background: $color-main;
        color: #fff;
        border-radius: 10px;
    }

    .discount-price {
        font-weight: $bold;
        @include font-size(1.8);
        margin: 10px 0;

        .total-discount{
            @include font-size(2);
        }
    }
    .error-message{
        color: $color-red;
        font-weight: $bold;
        margin: 5px 0;
    }
}
.accept-legal{
    input[type="checkbox"]{
        margin: 8px 6px 0 0;
    }

    a{
        @include color-link($color-orange);
    }
}
.accept-policy{
    @include font-size(1.4);
}



/*
    ////// SPECIFIC FOR SUBSCRIBED
*/

body.subscribed {
    .subscription-page{

        .generic-content {
            padding: 40px 100px;

            @media screen and (max-width: $wrapper) {
                padding: 40px 60px;
            }

            @include bp(desktop) {
                padding: 40px;
            }

            @include bp(mobile) {
                padding: 40px 20px;
            }
        }

        .trust-icons{
            display: none;
        }

        .money-choice, .submit-subscritpion{
            background: #fff;
            padding: 40px;
            border-radius: 40px;

            .promo-code{
                margin: 0;
            }
        }
        .policy-hint{
            margin: 20px 0;
        }
        .accept-policy{
            margin: 20px 0 0 0;
        }
    }
}


/*
    ////// SPECIFIC FOR REGISTERED
*/
body.registered{

    .subscription-page{

        .promo-and-subscription, .trust-icons{
            display: none;

        }

        .money-choice, .submit-subscritpion{
            background: #fff;
            padding: 40px;
            border-radius: 40px;

            .promo-code{
                margin: 0;
            }
        }
        .policy-hint{
            margin: 20px 0;
        }
        .accept-policy{
            margin: 20px 0 0 0;
        }
    }
}



/*
    ////// SUBSCRIPTION LANDING PREMIUM SIETE
*/
.premium-siete{
    @include bp(desktop){
        .subscription-page .subscription .register-and-subscription{
            order: 2;
        }
        .top-subscription{
            order: 1;
            
            .plans-features{
                float: none;
                margin: 0 auto;
            }
        }
    }
    @include bp(mobile){
        .top-subscription{
            background: none;
            padding: 0;
            margin-bottom: 40px;
        }
    }
}