/********************************************************************************* BUTTONS */

/*  index

    ////// MAIN STYLES
    ////// BUTTONS
*/

/*
    ////// MAIN STYLES
*/

.button, input[type="submit"]{
    @extend %font-family-main;
    display: inline-block;
    @extend %main-transition;
    width: auto;
    text-decoration: none;
    border: none;
    padding: 10px 30px;
    @include font-size(1.6);
    font-weight: $bold;
    text-align: center;
    border-radius: 20px;
    text-transform: uppercase;

    &.disabled{
        opacity: 0.4;
        pointer-events: none;
    }

    &.button_small{
        padding: 5px 10px;
        @include font-size(1.4);
    }

    @include bp(mobile){
        @include font-size(1.6);
    }
}

/*
    ////// BUTTONS
*/
.main-button, input[type="submit"].main-button{
    @extend .button;
    background: $color-orange;
    @include color-link(white);

    &:hover, &:active{
        background: darken($color-secondary,15%);
    }
}
.secondary_button{
    @extend .button;
    background: $color-main;
    @include color-link(white);

    &:hover, &:active{
        background: darken($color-main,15%);
    }
}
.outline_button{
    @extend .button;
    background: transparent;
    @include color-link($color-main);
    border: 1px solid $color-main;

    &:hover, &:active{
        background: $color-main;
        color: #fff;
    }
}

.print-button{
    @extend .button;
    background: url("../img/icon-print.svg") no-repeat 20px center $color-main;
    @include color-link(#fff);
    text-transform: none;
    font-weight: $regular;
    padding: 10px 50px;
    text-align: center;

    &:hover, &:active{
        background: url("../img/icon-print.svg") no-repeat 20px center $color-main;
    }
}