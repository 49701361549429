/********************************************************************************* TABLES */


/*  index

    ////// COMMONS
    ////// RESPONSIVE TABLES
*/


/*
    ////// COMMONS
*/
table{
    width: 100%;
    margin: 30px 0;

    caption{
        font-weight: $bold;
        padding: 10px 0;
    }
}
td{
    border: 1px solid $color-main;
    padding: 5px;

    @media screen and (max-width: 800px){
        white-space: nowrap;
    }
}


/*
    ////// RESPONSIVE TABLES
*/
.table-wrapper{
    @media screen and (max-width: 800px){
        width: 100%;
        overflow-x: auto;
    }
}

.main-table{
    tr{
        &:nth-child(1){
            td{
                padding-top: 10px;
            }
        }
    }
    td{
        border: none;
        padding: 3px;
        color: $color-third;
        vertical-align: top;
    }
    th{
        text-align: left;
        border-bottom: 1px solid $color-light-gray;
        margin-bottom: 10px;
        color: $color-third;
    }
}
