/********************************************************************************* SALAS */

/*  index

    ////// COMMONS
    ////// SALAS DE EJERCICIO
    ////// CATEGORIA
    ////// VIDEO DETAIL
    ////// MOTIVATOR MESSAGES

*/

/*
    ////// COMMONS
*/



/*
    ////// SALAS DE EJERCICIO
*/
.home-gym-title{
    @include font-size(3.6);
    text-transform: uppercase;
    margin: 0 0 20px 0;

    .subtitle{
        display: block;
        @include font-size(1.8);
        color: $color-dark-gray;
        font-weight: $regular;
    }
}
.subcategory-title{
    @include font-size(2);
    text-transform: uppercase;
    margin: 0 0 20px 0;
}

.salas-ejercicio{
    .salas-ejercicio-list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 50px;

        li{
            position: relative;
            border-radius: 20px;
            overflow: hidden;
            height: 300px;

            .brcm-image-and-caption{
                position: relative;
                z-index: 0;
                height: 100%;

                .brcm-image{
                    height: 100%;

                    a{
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                    }
                }
            }
            .sala-ejercicio-title{

                a{
                    @include color-link(#fff);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: 30px;
                    z-index: 10;
                    width: 100%;
                    height: 100%;
                    background: rgb(24,28,28);
                    background: linear-gradient(90deg, rgba(24,28,28,1) 40%, rgba(24,28,28,0) 100%);
                    @include font-size(2.8);
                    font-weight: $bold;
                    color: #fff;
                    text-transform: uppercase;
                    padding-top: 130px;
                    @extend %main-transition;
                    overflow: hidden;

                    &:hover{
                        background: rgb(223,83,55);
                        background: linear-gradient(90deg, rgba(223,83,55,1) 50%, rgba(223,83,55,0) 100%);
                    }

                    @media screen and (max-width: 1700px){
                        padding-top: 70px;
                        @include font-size(2.6);
                    }
                }
            }
            @media screen and (max-width: 1700px){
                height: 200px;
            }
        }

        @include bp(desktop){
            grid-template-columns: repeat(2, 1fr);
        }
        @include bp(mobile){
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
.see-more{
    margin: 20px 0;
    color: $color-orange;
    @include font-size(1.6);
    font-weight: $bold;
}

.main-button.fav-classes{
    float: right;

    @include bp(mobile){
        float: none;
        margin: 0 0 10px 0;
    }
}

/*
    ////// CATEGORIA
*/
.class-filters{
    position: relative;
    display: inline-block;
    float: right;

    @include bp(mobile){
        display: block;
        float: none;
    }
}
.trigger-class-filters{
    background: url("../img/circle.svg") no-repeat left 20px center $color-main;
    background-size: 15px;
    color: #fff;
    border: none;
    padding: 10px 50px;
    border-radius: 20px;
    position: relative;
    z-index: 100000;
    display: block;
    cursor: pointer;

    @include bp(mobile){
        float: none;
        margin: 0 0 10px 0;
    }
}
.class-levels-list{
    display: none;
    position: absolute;
    z-index: 10000;
    background: #fff;
    border-radius: 20px;
    top: 0;
    padding: 50px 10px 15px 10px;
    width: 100%;
    max-width: 230px;

    li{
        margin: 0;

        a{
            display: block;
            padding: 3px 10px;
            @include color-link($color-main);

            &:hover{
                color: $color-orange;
            }
        }
    }
}
.salas-ejercicio-video-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    margin-bottom: 30px;

    .aside-content &{
        grid-template-columns: repeat(4, 1fr);

        @include bp(desktop){
            grid-template-columns: repeat(2, 1fr);
        }
    }

    li{
        position: relative;
        margin: 0;
        min-height: 210px;
        overflow: hidden;

        &.watched{

            &:after{
                content: url("../img/icon-check-video.svg");
                position: absolute;
                right: 20px;
                bottom: 20px;
                z-index: 100;
            }

        }
    }
    .video-image{
        border-radius: 20px;
        overflow: hidden;
        height: 100%;
        display: block;

        .featured-video-image{
            height: 100%;
        }
        a{
            position: relative;
            z-index: 10;
            width: 100%;
            height: 100%;
            display: block;

            &:before{
                content: '';
                background: rgba($color-main, 0.7);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                min-height: 210px;
                display: block;
            }
        }
    }
    li{
        &.locked{
            position: relative;

            &:before{
                content: '';
                background: rgba($color-orange, 0.8);
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1000;
                border-radius: 20px;
            }
            .locked-message{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: 1000;
                background: url("../img/premium-star-current.svg") no-repeat center top;
                background-size: 50px;
                text-align: center;
                color: #fff;
                padding: 60px 0 0 0;
                width: 90%;

                h2{
                    @include font-size(2.2);
                    text-transform: uppercase;
                }
                p{
                    @include font-size(1.8);
                }
                a{
                    @include color-link(#fff);
                }
            }
            .video-information{
                a{
                    opacity: 0.6;
                }
            }
        }
    }
    .video-information{

        a{
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            height: 100%;
            @include flex-container;
            flex-direction: column;
            color: #fff;
            padding: 20px;
            @include color-link(#fff);

            @media screen and (max-width: 1750px){
                padding: 15px 20px;
            }
        }
    }

    .video-title{
        @include font-size(2);
        line-height: 26px;
        line-height: 2.6rem;
        font-weight: $bold;
        padding-right: 30px;
        text-transform: uppercase;

        @media screen and (max-width: 1750px){
            max-height: 50px;
            overflow: hidden;
            width: 90%;
        }
        @include bp(desktop){
            white-space: normal;
            overflow: auto;
            text-overflow: initial;
            width: 100%;
        }

    }
    .video-data{
        width: 100%;
    }
    .video-summary{
        @include font-size(1.8);

        @media screen and (max-width: 1750px){
            @include font-size(1.4);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
        }

        @include bp(desktop){
            white-space: normal;
            overflow: auto;
            text-overflow: initial;
            width: 100%;
        }
    }
    .video-summary-info{
        @include font-size(1.4);
        @include flex-container;
        justify-content: flex-start;
        height: auto;

        .video-time, .video-calories{
            margin: 0 10px 0 0;
        }
        .video-time{
            background: url("../img/icon-clock.svg") no-repeat left center;
            padding-left: 25px;
        }
    }
    .video-level{
        @include font-size(1.4);

        .video-level-title{
            color: $color-orange;
        }
        @media screen and (max-width: 1750px){
            @include font-size(1.2);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
        }
        @include bp(desktop){
            white-space: normal;
            overflow: auto;
            text-overflow: initial;
            width: 100%;
        }
    }

    @media screen and (max-width: 1750px){
        //grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;

    }
    @media screen and (max-width: 1300px){
        grid-template-columns: repeat(2, 1fr);
    }
    @include bp(tablet){
        grid-template-columns: repeat(2, 1fr);
    }
    @include bp(mobile){
        grid-template-columns: repeat(1, 1fr);
    }
}
.fav-session-action{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1000;


    input[type="checkbox"]{
        position: absolute;
        opacity: 0;
        z-index: 1;

        & + label{
            background: url("../img/icon-heart-outline.svg") no-repeat center center;
            display: block;
            height: 25px;
            max-width: none;
            position: relative;
            z-index: 10;
            width: 25px;
        }
        &:checked{
            & + label{
                background: url("../img/icon-heart.svg") no-repeat center center;
                display: block;
                width: 25px;
                height: 25px;
            }
        }
    }
}

/*
    ////// VIDEO DETAIL
*/
.video-detail-information{
    @include flex-container;
    height: auto;
    //width: calc(100% + 70px);

    .detail-video{
        @include flex-fixed-element(65%);
        background: $color-main;
        border-radius: 50px;
        display: grid;
        grid-template-columns: 4fr 1fr;
        color: #fff;
        position: relative;
        z-index: 100;

        @media screen and (max-width: 2000px){
            @include flex-fixed-element(70%);
        }

        @media screen and (max-width: 1300px){
            @include flex-fixed-element(100%);
            grid-template-columns: repeat(1, 1fr);
            border-radius: 40px 40px 0 0;
        }
    }
    .video-item{
        border-radius: 40px;
        overflow: hidden;

        @include bp(tablet){
            border-radius: 0;
        }
    }
    .video-data{
        padding: 70px 30px 30px 30px;
        position: relative;

        .fav-session-action{
            right: 30px;
        }

        @media screen and (max-width: 1300px){
            @include flex-container;
            height: auto;
            padding: 20px 70px 20px 30px;
        }
    }
    .video-info-area{
        //@include flex-fixed-element(40%);
        @include flex-fixed-element(33%);
        background: #fff;
        border-radius: 50px;
        position: relative;
        z-index: 10;
        //left: -70px;

        @media screen and (max-width: 2000px){
            //@include flex-fixed-element(30%);
            @include flex-fixed-element(27%);
            max-height: 500px;
            border-radius: 50px;
        }

        @media screen and (max-width: 1700px){
            max-height: 390px;
            padding-bottom: 20px;
        }

        @media screen and (max-width: 1300px){
            @include flex-fixed-element(100%);
            left: auto;
            border-radius: 0 0 40px 40px;
            max-height: none;
        }
    }
    .video-material{
        a{
            @include color-link(#fff);
        }
    }
    .video-teacher{
        background: $color-orange;
        color: #fff;
        //padding: 20px 20px 20px 100px;
        //border-radius: 0 50px 0 0;
        border-radius: 50px 50px 0 0;
        padding: 20px;
        @include flex-container;
        align-items: center;
        height: auto;

        h2{
            @include font-size(2.4);
            line-height: 30px;
            line-height: 3rem;
        }
        .teacher-image{
            @include flex-fixed-element(100px);
            height: 100px;
            overflow: hidden;
            border-radius: 100px;
            margin: 0 20px 0 0;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media screen and (max-width: 2000px) {
                height: 60px;
                @include flex-fixed-element(60px);
            }
        }
        .teacher-information{
            @include flex-flexible-element;
        }
        /*@media screen and (max-width: 2000px) {
            border-radius: 50px 50px 0 0;
            padding: 20px;
        }*/
        @media screen and (max-width: 1300px){
            border-radius: 0;
            padding: 20px;
        }
    }
    .video-description{
        //padding: 30px 30px 30px 100px;
        padding: 20px;
        max-height: 350px;
        overflow: auto;

        .video-title{
            @include font-size(2);
            line-height: 30px;
            line-height: 3rem;
            margin-bottom: 30px;
        }
        .video-description-title{
            @include font-size(1.8);
            color: $color-third;
            margin-bottom: 20px;
        }
        h2{
            margin: 0 0 5px 0;
        }
        h3{
            margin: 20px 0 5px 0;
        }
        div, p{
            &:empty, &.empty{
                display: none;
            }
            &::-moz-only-whitespace{
                display: none;
            }
        }

        /*@media screen and (max-width: 2000px) {
            padding: 20px;
        }*/
        @media screen and (max-width: 1700px){
            max-height: 220px;

            div{
                @include font-size(1.4);
            }
        }
        @media screen and (max-width: 1300px){
            max-height: none;
            padding: 30px 20px;
        }
    }
    .video-parameters{
        margin: 30px 0;

        li{
            margin: 0 0 30px 0;
        }

        @media screen and (max-width: 1700px){
            margin: 0;

            li{
                margin: 0 0 10px 0;

                @include bp(mobile){
                    margin: 0;
                }
            }
        }
        @include bp(mobile){
            @include flex-fixed-element(100%);
        }
    }
    .video-parameters-title{
        display: block;
        @include font-size(1.2);
        font-weight: $regular;
    }
    .video-parameters-value{
        display: block;
        @include font-size(1.8);
        font-weight: $regular;
        color: $color-orange;
    }
    .video-duration{
        background: url("../img/icon-clock.svg") no-repeat left 5px;
        padding-left: 25px;

        @include bp(mobile){
            @include flex-fixed-element(100%);
            margin: 0 0 10px 0;
        }
    }
    .video-material{
        background: url("../img/icon-layers.svg") no-repeat left 5px;
        padding-left: 25px;

        @include bp(mobile){
            @include flex-fixed-element(100%);
            margin: 0 0 10px 0;
        }
    }

    &.watched{
        .video-data{
            position: relative;

            &:after{
                content: url("../img/icon-check-video.svg");
                position: absolute;
                right: 30px;
                bottom: 20px;
                z-index: 100;
            }

        }
    }

    @media screen and (max-width: 1300px){
        width: 100%;
    }
}

.video-related-content{
    margin: 60px 0;

    h3{
        @include font-size(1.8);
        color: $color-dark-gray;
        font-weight: $regular;
        margin: 0 0 20px 0;
    }
}


/*
    ////// MOTIVATOR MESSAGES
*/
.motivator-region{
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
    padding-top: 120px;

    &.hiding{
        animation: fadeOut;
        animation-duration: 0.5s;
        animation-timing-function: ease-out;
        animation-delay: 0s;
        animation-fill-mode: forwards;
    }

    .motivator-overlayer{
        background: rgba($color-main,0.8);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .motivator-wrapper{
        position: relative;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        max-width: 830px;

        @include bp(tablet){
            max-width: 90%;
        }
    }
    .motivator-content{
        background: #fff;
        text-align: center;
        padding: 70px;
        border-radius: 50px;

        @include bp(tablet){
            padding: 40px;
        }

        @include bp(mobile){
            padding: 30px;
        }
    }
    .close-motivator-region{
        background: url("../img/icon-cross.svg") no-repeat center center;
        border: none;
        width: 30px;
        height: 30px;
        display: block;
        float: right;
        margin-right: -50px;

        @include bp(desktop){
            margin-right: 0;
            margin-top: -25px;
        }
    }
    .message{
        &.error{
            color: $color-red;
        }
    }
    .motivator-message{
        @include font-size(2.4);
        font-weight: $bold;
        text-transform: uppercase;
        margin: 20px 0;
    }
    .motivator-actions{
        @include flex-container;
    }
    .motivator-points{
        @include flex-fixed-element(60%);
        background: $color-orange;
        padding: 40px;
        color: #fff;
        border-radius: 50px;

        @include bp(mobile){
            @include flex-fixed-element(100%);
            margin: 0 0 10px 0;
            padding: 10px 20px;
        }
    }
    .total-points-message, .total-medals-message{
        text-transform: uppercase;

        .motivator-points-number{
            font-weight: $bold;
            @include font-size(5);

            @include bp(mobile){
                @include font-size(4);
                line-height: 60px;
                line-height: 6rem;
            }
        }
        .motivator-points-title{
            font-weight: $regular;
            display: block;
            @include font-size(2);
        }
    }
    .motivator-share{
        @include flex-fixed-element(40%);

        p{
            margin: 0 0 5px 0;
            color: $color-third;
        }

        @include bp(mobile){
            @include flex-fixed-element(100%);
        }
    }
    .motivator-social-share{
        @include flex-container;
        justify-content: center;
        margin-left: 20px;

        li{
            @include flex-fixed-element(100%);

            &:nth-child(1){
                margin: 0 0 20px 0;

                @include bp(mobile){
                    margin: 0 5px 0 0;
                }
            }

            @include bp(mobile){
                @include flex-fixed-element(48%);
                margin: 0;
            }
        }

        a{
            display: block;
            height: auto;
            width: 100%;
            border-radius: 20px;
            position: relative;
            z-index: 100;

            img{
                display: block;
                border-radius: 10px;
                overflow: hidden;
            }
        }
        .social-share-points{
            color: $color-third;
            background: $color-brown;
            @include font-size(2);
            font-weight: $bold;
            width: 100%;
            display: block;
            padding: 15px 20px 10px 20px;
            text-align: center;
            border-radius: 10px;
            position: relative;
            z-index: 10;
            margin-top: -15px;
            max-width: 195px;
        }
    }
}