/********************************************************************************* COMMONS */

/*  index

    ////// GLOBALS
*/



/*
    ////// GLOBAL SYTLES
*/
html{
    //scroll-behavior: smooth;
}
body {
    @extend %font-family-secondary;
    @include font-size(1.6);
    color: $color-main;
    font-weight: $regular;

    &.frozen{
        overflow: hidden;
    }
}
ul{
    li{
        list-style-type: none;
    }
}
ol{
    li{
        list-style-type: decimal;
        list-style-position: inside;
    }
}
a{
    cursor: pointer;

    &:link{ color: $color-main;}
    &:hover{}
    &:visited{color: $color-main;}
    &:active{color: $color-main;}
}
img{
    max-width: 100%;
}
h1{
    @extend %font-family-main;
}
.hint{
    @include font-size(1.2);
    font-style: italic;
    margin: 10px 0;
}
.only-print {
    display: none;
}
.generic-content{
    //background: $color-brown;
    padding: 40px 100px;


    body.registered &, body.subscribed & {
        padding: 40px 100px;
        background: $color-brown;
        min-height: 60vh;

        @media screen and (max-width: 1700px) {
            padding: 40px 50px;
        }

        @include bp(desktop) {
            padding: 40px;
        }

        @include bp(mobile) {
            padding: 40px 20px;
        }
    }

    @media screen and (max-width: $wrapper){
        padding: 40px 60px;
    }

    @include bp(desktop){
        padding: 40px;
    }

    @include bp(mobile){
        padding: 40px 20px;
    }

}
.main-title{
    @include font-size(3.5);
    text-transform: uppercase;
    margin: 0 0 10px 0;
    @extend %font-family-main;

    @include bp(tablet){
        @include font-size(3);
    }
}
.aside-content{
    margin-top: 40px;

    h3{
        color: $color-third;
        @include font-size(2.2);
        font-weight: $regular;
        margin: 0 0 15px 0;
    }

    body.registered &{
        display: none;
    }
}
.subscribed .main-content, .registered .main-content{
    padding-bottom: 40px;
}
.narrow-content{
    max-width: 550px;
    margin: 0 auto;

    @include bp(tablet){
        max-width: none;
    }
}
.alert-message{
    color: $color-red;
}
.subscribed{
    .generic-content{
        background: $color-brown;
    }
}
.subscribed, .registered, .public{
    padding-top: 90px;
}

.reminder{
    border: 1px solid $color-orange;
    padding: 10px 20px;
    margin: 20px 0;
    font-weight: $bold;

    a{
        @include color-link($color-orange);
    }
}
.cookies{
    padding: 10px 20px;
    border-bottom: 1px solid $color-brown;
    text-align: center;
    position: relative;
    z-index: 1000;
    background: #fff;

    .close-message{
        a{
            background: url("../img/icon-cross.svg") no-repeat center center $color-orange;
            display: block;
            height: 20px;
            width: 20px;
            float: right;
            background-size: 10px;
        }
    }
    p{
        @include font-size(1.2);


        a{
            @include color-link($color-orange);
        }
    }
}

.flash-message{
    @include font-size(1.4);
    padding: 7px 10px;
    text-align: center;

    &.success{
        background: $color-green;
        color: #fff;
    }
    &.error{
        background: $color-red;
        color: #fff;
    }
    .close-message{
        float: right;

        a{
            background: url("../img/icon-cross.svg") no-repeat center center;
            background-size: 15px;
            display: block;
            width: 20px;
            height: 20px;
        }
    }
}