/********************************************************************************* FEEDBACK */

/*  index

    ////// COMMON STYLES
    ////// ALERTS
*/


/*
    ////// COMMON STYLES
*/
.feedback{
    margin: 10px auto;
    text-align: left;
    max-width: $wrapper;

    .alert-box{
        padding: 10px 20px;
        text-align: center;

        p{
            @include font-size(1.6);
        }
    }

    &.success{
        padding: 7px 5px;
        color: $color-green;

        h1{
            color: $color-green;
            font-weight: $bold;
        }

        .alert-box{
            background: $color-green;
            color: #fff;
        }
    }
    &.error{
        padding: 7px 5px;
        color: $color-red;

        h1{
            color: $color-red;
            font-weight: $bold;
        }
        .alert-box{
            background: $color-red;
            color: #fff;
        }
    }
    p{
        @include font-size(1.4);
        margin: 0;

        a{
            text-decoration: underline;
            font-weight: $bold;
        }
    }
}

/*
    ////// ALERTS
*/
.alert{
    &.info{
        padding: 20px;

        a{
            text-decoration: underline;
        }
        p{
            margin: 0;
            @include font-size(1.4);
        }
    }
}