/********************************************************************************* PAGER */

/*  index

    ////// COMMON STYLES
*/


/*
    ////// COMMON STYLES
*/

.paginator{
    margin: 40px auto;
    max-width: 500px;

    ul{
        @include flex-container;
        justify-content: center;
    }
    li{
        @include font-size(1.4);

        &.previous{
            @include flex-fixed-element(20%);
            text-align: left;

            a{
                background: url("../img/arrow-left.svg") no-repeat left center;
                display: block;
                width: 13px;
                height: 23px;
            }
        }
        &.current{
            @include flex-flexible-element;
            text-align: center;
        }
        &.next{
            @include flex-fixed-element(20%);
            text-align: right;

            a{
                background: url("../img/arrow-right.svg") no-repeat right center;
                display: block;
                width: 13px;
                height: 23px;
            }
        }
    }
}
