/********************************************************************************* GRID */

/*  index

    ////// GLOBALS
    ////// FLEXBOX
    ////// GRID
*/

/*
    ////// GLOBALS
*/
html, body {
    height: 100%;
}

.central-wrapper{
    @include flex-container;
    height: auto;
    position: relative;
    z-index: 10;
}
.sidebar{
    @include flex-fixed-element(20%);

    @include bp(desktop){
        display: none;
        position: fixed;
        top: 80px;
        right: 0;
        width: 50%;
        z-index: 1001;
    }
    @include bp(tablet){
        width: 90%;
    }
    @media screen and (min-width: 1800px){
        @include flex-fixed-element(15%);
    }
}
.main-wrapper{
    //width: 100%;
    //margin: 0 auto;
    @include flex-flexible-element;
}
.generic-content{
    @include flex-container;
    height: auto;

    .main-content{
        @include flex-flexible-element;

        @include bp(desktop){
            @include flex-fixed-element(100%);
            margin: 0 0 20px 0;
        }
    }
    .aside-content{
        @include flex-fixed-element(30%);
        margin-left: 60px;

        @include bp(desktop){
            @include flex-fixed-element(100%);
            margin: 0;
        }
    }
}

.center-wrapper {
    max-width: $wrapper;
    margin: 0 auto;
}
.main-content{

    @media screen and (max-width: $wrapper){
        padding: 0 10px;
    }
}

/*
    ////// FLEXBOX
*/
.row {
    @include flex-container;
    height: auto;
}
.one-third-column {
    @include flex-fixed-element(32%);

    & + .two-third-column {
        @include flex-flexible-element;
        margin: 0 0 0 40px;
    }
}
.two-third-column {
    @include flex-fixed-element(65%);

    & + .one-third-column {
        @include flex-flexible-element;
        margin: 0 0 0 40px;
    }
}
.one-fourth-column {
    @include flex-fixed-element(22%);
}
.three-fourth-column {
    @include flex-fixed-element(75%);
}
.half-column {
    @include flex-fixed-element(48%);
}
.col-8-12{
    @include flex-fixed-element(66%);
}
.col-4-12{
    @include flex-fixed-element(33%);
}
.col-5-12{
    @include flex-fixed-element(42%);
}
.col-7-12{
    @include flex-fixed-element(58%);
}
.full-width{
    @include flex-fixed-element(100%);
}

@include bp(tablet){

    .one-third-column {
        @include flex-fixed-element(100%);
        margin: 0 0 20px 0;

        & + .two-third-column {
            @include flex-fixed-element(100%);
            margin: 0 0 20px 0;
        }
    }
    .two-third-column {
        @include flex-fixed-element(100%);
        margin: 0 0 20px 0;

        & + .one-third-column {
            @include flex-fixed-element(100%);
            margin: 0 0 20px 0;
        }
    }
    .one-fourth-column {
        @include flex-fixed-element(100%);
        margin: 0 0 20px 0;
    }
    .three-fourth-column {
        @include flex-fixed-element(100%);
        margin: 0 0 20px 0;
    }
    .half-column {
        @include flex-fixed-element(100%);
        margin: 0 0 20px 0;
    }
    .col-4-12, .col-5-12, .col-7-12, .col-8-12{
        @include flex-fixed-element(100%);
        margin: 0 0 20px 0;
    }

}


/*
    ////// GRID
*/

/*.content-grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin: 30px 0;

    @include bp(tablet){
        grid-template-columns: 1fr;
    }

    &.content_50_50{
        grid-template-columns: repeat(2, 1fr);

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    &.content_35_65{
        grid-template-columns: 3.5fr 6.5fr;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    &.content_65_35{
        grid-template-columns: 6.5fr 3.5fr;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    &.content_20_80{
        grid-template-columns: 2fr 8fr;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    &.content_80_20{
        grid-template-columns: 8fr 2fr;

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
    &.content_33_33_33{
        grid-template-columns: repeat(3, 1fr);

        @include bp(tablet){
            grid-template-columns: 1fr;
        }
    }
}*/

