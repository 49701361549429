/********************************************************************************* DIETS WELLWO */

/*  index

    ////// HOME DIETS
    ////// DIET DETAIL

*/

/*
    ////// HOME DIETS
*/
.diets{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;

    @include bp(tablet){
        grid-template-columns: repeat(1, 1fr);
    }
}

.diet{
    background: #fff;
    text-align: center;
    border-radius: 40px;
    padding-bottom: 30px;

    h2{
        background: $color-main;
        color: #fff;
        padding: 20px;
        text-align: center;
        border-radius: 40px 40px 0 0 ;
    }
}
.percentage{
    padding: 30px 0;

    button{
        pointer-events: none;
    }
}
.diet-not-started{
    background: #fff;
    border-radius: 40px;
    margin: 30px 0;
    text-align: center;
    padding: 30px;

    .outline_button{
        margin: 20px 0;
    }
}


/*
    ////// DIET DETAIL
*/
.diet-detail-pecentage{
    margin: 20px 0;

    p{
        @include font-size(1.4);
    }
    .diet-detail-percentage-value{
        @include font-size(1.8);
        font-weight: $bold;
    }
    .diet-detail-progress-bar{
        background: $color-light-gray;
        height: 20px;
        width: 100%;
        border-radius: 40px;
        position: relative;

    }
    .diet-detail-progress-bar-value{
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        max-width: 99.5%;
        height: 16px;
        background: $color-green;
        border-radius: 40px;

        /*&:after{
            content: data-progress;
        }*/
    }
}
.diet-detail-nutritional{
    @include flex-fixed-element(30%);

    h2{
        background: $color-main;
        color: #fff;
        border-radius: 40px 40px 0 0;
        padding: 20px 40px;
        text-transform: none;
        text-align: center;
    }

    @include bp(desktop){
        @include flex-fixed-element(100%);
        order: 2;
    }
}
.diet-detail-nutritional-list{
    background: #fff;
    border-radius: 0 0 40px 40px;

    li{
        padding: 10px 40px;

        &:last-child{
            background: $color-orange;
            border-radius: 0 0 40px 40px;
            color: #fff;
        }
    }
    .nutrition-title{
        display: inline-block;
        vertical-align: top;
    }
    .nutrition-value{
        display: inline-block;
        vertical-align: top;
        float: right;
    }
}
.diet-detail-actions{
    @include flex-container;
    height: auto;

    li{
        @media screen and (max-width: 1700px){
            display: block;
            margin: 0 0 10px 0;
            width: 100%;

            .print-button, .outline_button{
                width: 100%;
                display: block;
            }
        }
    }
}

.diet-detail-info{
    @include flex-container;
    height: auto;
}
.diet-detail-plan{
    @include flex-fixed-element(60%);
    background: #fff;
    padding: 40px;
    border-radius: 40px;

    .content{
        h1{
            margin: 0 0 20px 0;
            @include font-size(1.8);
        }
        ul{
            margin: 10px 0;
        }
        li{
            background: url(../img/icon-list.png) no-repeat left 10px;
            padding: 1px 0 1px 15px;
            margin: 0 0 3px 0;
            @include font-size(1.4);
        }
    }
    @include bp(desktop){
        @include flex-fixed-element(100%);
        order: 1;
        margin: 0 0 30px 0;
    }
}
#dietDayContainer div.loader {
    display: none;
}
#dietDayContainer.loading div.loader {
    display: block;
    color: $color-orange;
    text-align: center;
}
#dietDayContainer div.error {
    display: none;
    color: $color-red;
}
#dietDayContainer.error div.error {
    display: block;
}

.diet-description{
    border-bottom: 1px solid $color-brown;
    margin: 0 0 20px 0;
    padding: 0 0 10px 0;

    p{
        @include font-size(1.4);
    }
}

.diet-filters{
    margin: 20px 0;
    @include flex-container;
    height: auto;
    align-items: center;
    background: #fff;
    border-radius: 40px;

    .diet-filters-title{
        background: $color-main;
        color: #fff;
        padding: 20px 40px;
        border-radius: 40px 0 0 40px;
        font-weight: $regular;

        @include bp(tablet){
            @include flex-fixed-element(100%);
            border-radius: 0;
        }
    }
    .reset-diet{
        background: url("../img/icon-refresh.svg") no-repeat right 20px center $color-main;
        color: #fff;
        padding: 20px 60px 20px 20px;
        border-radius: 0 40px 40px 0;

        @include bp(tablet){
            @include flex-fixed-element(100%);
            border-radius: 0;
        }
    }
    .style-select{
        width: auto;
        border: none;

        &.hidden{
            display: none;
        }
    }
}