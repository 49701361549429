%font-family-main{
    font-family: 'Open Sans', sans-serif;
}
%font-family-secondary{
    font-family: 'Montserrat', sans-serif;
}

/*Font Variables*/
    $light: 	400;
    $regular: 	400;
    $bold: 		700;


/*FONT SIZE*/
html{
    font-size: 62.5%;
}