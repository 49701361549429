/********************************************************************************* MENUS */

/*  index

    ////// GLOBALS
    ////// MAIN MENU
    ////// SECONDARY MENU
    ////// MOBILE MENU
*/

/*
    ////// GLOBALS
*/
nav {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}



/*
    ////// USER MENU
*/
.user-menu{
    ul{
        @include flex-container;
    }


    li{
        @include flex-fixed-element(100%);
        text-transform: uppercase;
        @include font-size(1.6);
        font-weight: $bold;

        a{
            display: block;
            position: relative;
            width: 100%;
            padding: 20px 0 20px 20px;
            @include color-link(#fff);
            min-height: 75px;
            @extend %main-transition;

            @include bp(desktop){
                min-height: auto;
            }
            @media screen and (max-height: 655px){
                min-height: auto;
                padding: 10px 0 10px 20px;
            }
        }

        &.current{
            a{
                background: $color-orange;
            }
        }
        &:hover{
            a{
                background: $color-orange;
            }
        }
    }
}

body.registered{
    .user-menu{
        .menu-diets, .menu-training-plans, .menu-recipes, .menu-classes-clam, .menu-lives, .menu-classes{
            a{
                &:after{
                    content: url("../img/premium-star.svg");
                    text-align: center;
                    position: absolute;
                    color: #fff;
                    @include font-size(1.2);
                    padding: 25px 15px;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 100%;
                    box-sizing: border-box;
                }
            }
            &:hover, &.current{
                a{
                    &:after{
                        content: url("../img/premium-star-current.svg");
                    }
                }
            }
        }
    }
}



/*
    ////// MOBILE MENU
*/
.mobile-menu{
    display: none;

    @include bp(desktop){
        display: block;
    }
}