/********************************************************************************* MOTIVATOR */

/*  index

    ////// COMMONS
    ////// RANKING
    ////// PRIZES


*/

/*
    ////// COMMONS
*/



/*
    ////// RANKING
*/
.ranking{
    @include flex-container;
}
.month-ranking, .year-ranking{
    @include flex-fixed-element(48%);

    @include bp(tablet){
        @include flex-fixed-element(100%);
        margin: 0 0 30px 0;
    }
}

.point-system{
    @include color-link($color-orange);
    text-align: right;
}
.ranking-header{
    background: $color-main;
    color: #fff;
    border-radius: 50px 50px 0 0;
    padding: 20px 40px;
    text-align: center;
}
.ranking-header-period{
    @include font-size(2);
    text-transform: uppercase;
}
.your-ranking{
    @include flex-container;
    justify-content: center;

    li{
        margin: 0 10px;
    }
}
.ranking-table{
    background: #fff;
    padding: 20px 40px;

    th{
        border-bottom: 1px solid $color-main;
        text-align: left;
    }
    td{
        border: none;
    }
    @include bp(desktop){
        td{
            &[data-th="Nombre"]{
                overflow: hidden;
                text-overflow: ellipsis;
                width: 90px;
                white-space: nowrap;
                display: inline-block;
            }
        }
    }

    .ranking-position{
        @include font-size(2);
        font-weight: $bold;
    }
    .gold{
        background: url("../img/badge-gold.svg") no-repeat center center;
        width: 30px;
        height: 15px;
    }
    .silver{
        background: url("../img/badge-silver.svg") no-repeat center center;
        width: 30px;
        height: 15px;
    }
    .bronze{
        background: url("../img/badge-bronze.svg") no-repeat center center;
        width: 30px;
        height: 15px;
    }

    @include bp(tablet){
        padding: 20px;

        td, th{
            @include font-size(1.4);
        }
    }
}
.points-ranking{
    margin: 30px 0;

    .ranking-table{
        border-radius: 50px;
    }
}



/*
    ////// PRIZES
*/
.yearly-prizes, .monthly-prizes{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    margin: 10px 0 40px 0;

    li{
        position: relative;
    }
    .prize-image{
        position: relative;
        z-index: 10;
        @extend %main-transition;
        border-radius: 40px;
        overflow: hidden;
        height: 100%;

        &:after{
            content: "";
            background: rgba($color-main,0.7);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }

        &:hover{
            &:after{
                display: none;
            }
        }
    }
    .prize-summary{
        position: absolute;
        z-index: 100;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;

        p{
            @include font-size(2);
            text-transform: uppercase;
            text-align: center;
        }
    }

    @include bp(mobile){
        grid-template-columns: repeat(1, 1fr);
    }
}

.prize-title, .title-block{
    @include flex-container;
    margin: 30px 0 10px 0;
}
.prizes{
    h3{
        @include font-size(1.8);
        font-weight: $regular;
        text-transform: uppercase;
        color: $color-third;
    }

    .see-conditions{
        @include color-link($color-orange);
        font-weight: $bold;
        float: right;
    }
}
.no-prizes{
    color: $color-red;
    margin: 0 0 10px 0;
    font-style: italic;
    @include font-size(1.4);
}
.title-block{
    margin: 20px 0;

    h2{
        @include font-size(2);
        text-transform: uppercase;
    }
}
.points{
    margin: 60px 0;
}
.see-all-points, .business-prizes{
    @include color-link($color-orange);
    font-weight: $bold;
    float: right;
    margin: 10px 0 0 0;
}
.motivator-clasificacion{
    margin-top: 50px;
}