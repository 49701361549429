/********************************************************************************* RECIPES */

/*  index

    ////// HOME RECIPES
    ////// RECIPE DETAIL

*/

/*
    ////// HOME RECIPES
*/
.results-filters{

    .style-select{
        background: url("../img/circle.svg") no-repeat left 20px center $color-main;
        background-size: 15px;
        color: #fff;
        border: none;
        padding: 10px 50px;
        border-radius: 20px;
        float: right;
        display: inline-block;
        max-width: 230px;

        select{
            padding: 0;
        }

        @include bp(mobile){
            float: none;
            margin: 0 0 10px 0;
        }
    }
}

.receipts-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;

    li{
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        height: 300px;

        .brcm-image-and-caption{
            position: relative;
            z-index: 0;
            height: 100%;

            .brcm-image{
                height: 100%;

                a{
                    width: 100%;
                    height: 100%;
                    display: block;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .diet-receipt-title{

            a{
                @include color-link(#fff);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding: 30px;
                z-index: 10;
                width: 100%;
                height: 100%;
                background: rgb(24,28,28);
                background: linear-gradient(90deg, rgba(24,28,28,1) 40%, rgba(24,28,28,0) 100%);
                @include font-size(2.8);
                font-weight: $bold;
                color: #fff;
                text-transform: uppercase;
                padding-top: 130px;
                @extend %main-transition;

                &:hover{
                    background: rgb(223,83,55);
                    background: linear-gradient(90deg, rgba(223,83,55,1) 50%, rgba(223,83,55,0) 100%);
                }
            }
        }
    }

    @include bp(desktop){
        grid-template-columns: repeat(2, 1fr);
    }
    @include bp(mobile){
        grid-template-columns: repeat(1, 1fr);
    }
}
.category_filters{
    display: none;
    position: absolute;
    z-index: 10000;
    background: #fff;
    border-radius: 20px;
    top: 0;
    padding: 50px 10px 15px 10px;
    width: 100%;
    max-width: 230px;

    li{
        margin: 0;
        display: block;
        padding: 3px 10px;
        @include color-link($color-main);

        &:hover{
            color: $color-orange;
            cursor: pointer;
        }

        &.selected{
            color: $color-orange;
        }
    }
}


/*
    ////// RECIPE DETAIL
*/
.recipe-detail{
    @include flex-container;

    .recipe-content{
        @include flex-fixed-element(60%);

        .brcm-pagina-element{
            padding: 0;
        }
        .brcm-generic-element-with-big-images{
            margin: 0;
        }

        @include bp(tablet){
            @include flex-fixed-element(100%);
            margin: 0 0 40px 0;
        }
    }
    .eating-options{
        background: #fff;
        border-radius: 0 0 40px 40px;
        padding: 20px;
    }
    .nutrition-info{
        @include flex-fixed-element(30%);

        @include bp(tablet){
            @include flex-fixed-element(100%);
        }

    }
    .receipt-image{

        .brcm-image{
            height: 250px;
            overflow: hidden;
            border-radius: 40px 40px 0 0;
        }
        a{
            display: block;
            height: 100%;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.nutrition-info{
    .brcm-body{
        background: #fff;
        border-radius: 40px;

        h3{
            background: $color-main;
            color: #fff;
            border-radius: 40px 40px 0 0;
            padding: 20px 40px;
            text-transform: none;
            text-align: center;
        }
        ul{
            padding: 20px;
        }
        .SG-nutrition-value{
            float: right;
        }
    }
    .print-button{
        float: right;
    }
}
.main-content .nutrition-info .brcm-generic-element-with-images-on-the-right .brcm-body ul li{
    background: none;
    padding: 0;
    margin: 0 0 10px 0;
}