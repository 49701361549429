/********************************************************************************* FOOTER */

/*  index

    ////// MAIN FOOTER+
*/


/*
    ////// MAIN FOOTER
*/
.footer{
    .brcm-pagina-element{
        padding: 0;
    }
}
.footer-one, .footer-two{
    @include flex-container;
    padding: 40px;

    .brcm-body{
        margin: 0 0 20px 0;
        p{
            @include font-size(1.4);
            text-transform: uppercase;
            margin: 0 0 10px 0;
            font-weight: $bold;
        }
        li{
            @include font-size(1.4);
            margin: 0 0 3px 0;

            a{
                text-decoration: none;

                &:hover{
                    color: $color-orange;
                }
            }
            @include bp(mobile){
                @include font-size(1.2);
            }
        }
    }
}
.footer-one{
    background: $color-main;
    color: white;

    a{
        @include color-link(#fff);
    }
    .column{
        @include bp(tablet){
            @include flex-fixed-element(50%);
        }
        @include bp(mobile){
            @include flex-fixed-element(100%);
        }
    }
    @include bp(tablet){
        @include flex-container;
    }
}
.footer-two{
    background: $color-brown;
    @include flex-container;
    height: auto;

    a{
        @include color-link($color-main);
    }

    .column{
        &:nth-child(1){
            @include flex-fixed-element(55%);

            ul{
                @include column(4,auto);
                -webkit-column-span: all;
                column-span: all;
            }
        }
        &:nth-child(2){
            @include flex-fixed-element(20%);

            ul{
                @include column(1,auto);
                -webkit-column-span: all;
                column-span: all;
            }
        }
        &:nth-child(3){
            @include flex-fixed-element(20%);

            ul{
                @include column(1,auto);
                -webkit-column-span: all;
                column-span: all;
            }
        }

        @include bp(tablet){
            &:nth-child(1), &:nth-child(2), &:nth-child(3){
                @include flex-fixed-element(48%);

                ul{
                    @include column(1,auto);
                }
            }
        }

        @include bp(mobile){
            &:nth-child(1), &:nth-child(2), &:nth-child(3){
                @include flex-fixed-element(100%);
            }
        }
    }
}
.footer-gallery {
    background: $color-main;
    color: white;
    padding: 20px;
}
.copyright-footer {
    width: 100%;
    padding: 10px;
    margin: 20px auto;
    text-align: center;

    p{
        @include font-size(1.4);
    }
}
.credits {
    margin: 20px 0 0 0;
    padding: 10px 0;
    border-top: 1px solid $color-brown;
    text-align: center;

    p{
        @include font-size(1.4);
    }
}