/********************************************************************************* FORMS */

/*  index

    ////// COMMON ELEMENTS
    ////// STYLE SELECT ELEMENT
    ////// ERROR MESSAGES
    ////// CHECKBOX & RADIO BUTTONS
*/

/*
    ////// COMMON ELEMENTS
*/

input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="search"], input[type="number"] {
	padding: 10px 20px;
	box-sizing: border-box;
    border: 1px solid $color-main;
	@include font-size(1.6);
    display: block;
    width: 100%;
    color: $color-main;

    &:disabled{
        opacity: 0.6;
        background: $color-light-gray;
        cursor: not-allowed;
    }
}
input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="tel"]:focus, input[type="search"]:focus, input[type="number"]:focus{
    border: 1px solid $color-main;
    background: $color-secondary;
}
label{
    display: block;
}
input[type="checkbox"], input[type="radio"]{
	vertical-align: middle;	
	display: inline-block;
	float: left;
	margin: 3px 6px 0 0;
    
    & + label {
        display: inline-block;
        cursor: pointer;
        max-width: calc(100% - 20px);
    }
}
textarea {
	padding: 10px 20px;
	box-sizing: border-box;
	min-height: 100px;
    border: 1px solid $color-main;
	border-radius: 3px;
	@include font-size(1.6);
	width: 100%;
    
    &:focus{
        background: $color-secondary;
    }
}
fieldset {
    border: none;
    margin: 0 0 7px 0;
    padding: 0;
}
input{
    ::-webkit-input-placeholder {
        @include font-size(1.6);
    }

    :-moz-placeholder { /* Firefox 18- */
        @include font-size(1.6);
    }

    ::-moz-placeholder {  /* Firefox 19+ */
        @include font-size(1.6);
    }

    :-ms-input-placeholder {
        @include font-size(1.6);
    }
}

/*
    ////// STYLE SELECT ELEMENT
*/
.style-select {
    background: url("../img/arrow-down.svg") no-repeat right 10px center white;
    background: none\0;
    width: 100%;
    border: 1px solid $color-main;
    border: none\0;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    /*-moz-appearance:window;*/
    //padding-right: 40px;
    padding-left: 10px;

    select {
        background: transparent;
        background: white\0;
        -webkit-appearance: none;
        -moz-appearance: none;
        /*-moz-appearance:window;*/
        width: 100%!important;
        padding: 10px;
        border: none;
        border: 1px solid $color-main \9;
        padding-right: 40px;

        &:hover{
            cursor: pointer;
        }
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   /* IE10+ specific styles go here */
   .style-select {
    background: none;
    border: none;

    select {
        background: white;
    }
   }
}


/*
    ////// ERROR MESSAGES
*/
fieldset{

    &.error{
        border: 1px dotted $color-red;
        padding: 5px;
        border-radius: 5px;
    }
    
    p.error{
        text-align: left;
        color: $color-red;
        @include font-size(1.2);
        font-weight: $bold;
        margin: 5px 0 0 0;
    }
}



/*
    ////// CHECKBOX & RADIO BUTTONS
*/
.checkbox-group{
    input[type="checkbox"]{
        position: absolute;
        opacity: 0;
        z-index: 1;

        & + label{
            position: relative;
            z-index: 10;
            width: 100%;
            padding: 5px 0 5px 30px;
            @extend %main-transition;
            background: url("../img/icon-checkbox.svg") no-repeat left center;
            box-sizing: border-box;
            @include font-size(1.4);
            font-weight: $regular;

            a{
                @include color-link($color-orange);
                text-decoration: underline;
            }
        }
        &:checked + label{
            background: url("../img/icon-checkbox-checked.svg") no-repeat left center;
        }
    }
}
.radiobutton-group{
    input[type="radio"]{
        position: absolute;
        opacity: 0;
        z-index: 1;

        & + label{
            position: relative;
            z-index: 10;
            width: 100%;
            padding: 5px 0 5px 20px;
            @extend %main-transition;
            background: url("../img/icon-tick.svg") no-repeat left center;
            box-sizing: border-box;
            @include font-size(1.4);
            font-weight: $regular;

            a{
                @include color-link($color-orange);
                text-decoration: underline;
            }
        }
        &:checked + label{
            background: url("../img/icon-tick-checked.svg") no-repeat left center;
        }
    }
}

.form-box{
    background: #fff;
    border-radius: 40px;
    margin: 30px 0;
    padding: 40px;

    input[type="text"], input[type="email"], input[type="password"], textarea{
        border: none;
        border-bottom: 1px solid $color-third;
        padding: 5px 0;
    }
    label{
        font-weight: $bold;
        color: $color-third
    }
    input[type="submit"].main-button{
        display: block;
        width: 100%;
        margin: 20px 0;
        padding: 20px 30px;
    }
    .field-block{
        margin: 0 0 10px 0;
    }
    .brcm-field{
        margin: 0 0 20px 0;
    }
    .brcm-title, .field-block{
        label{
            display: inline-block;
            vertical-align: top;
            @include font-size(1.4);
            font-weight: $regular;
        }
        .brcm-required{
            display: inline-block;
            vertical-align: top;
        }
    }
    .brcm-description{
        margin: 10px 0;

        p{
            @include font-size(1.2);
        }
        a{
            @include color-link($color-orange);
        }
    }
}