/*ANIMATION*/

//@-webkit-keyframes bounceInDown {
//  0%, 60%, 75%, 90%, 100% {
//    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
//            transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
//  }
//
//  0% {
//    opacity: 0;
//    -webkit-transform: translate3d(0, -3000px, 0);
//            transform: translate3d(0, -3000px, 0);
//  }
//
//  60% {
//    opacity: 1;
//    -webkit-transform: translate3d(0, 25px, 0);
//            transform: translate3d(0, 25px, 0);
//  }
//
//  75% {
//    -webkit-transform: translate3d(0, -10px, 0);
//            transform: translate3d(0, -10px, 0);
//  }
//
//  90% {
//    -webkit-transform: translate3d(0, 5px, 0);
//            transform: translate3d(0, 5px, 0);
//  }
//
//  100% {
//    -webkit-transform: none;
//            transform: none;
//  }
//}
//
//@keyframes bounceInDown {
//  0%, 60%, 75%, 90%, 100% {
//    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
//            transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
//  }
//
//  0% {
//    opacity: 0;
//    -webkit-transform: translate3d(0, -3000px, 0);
//            transform: translate3d(0, -3000px, 0);
//  }
//
//  60% {
//    opacity: 1;
//    -webkit-transform: translate3d(0, 25px, 0);
//            transform: translate3d(0, 25px, 0);
//  }
//
//  75% {
//    -webkit-transform: translate3d(0, -10px, 0);
//            transform: translate3d(0, -10px, 0);
//  }
//
//  90% {
//    -webkit-transform: translate3d(0, 5px, 0);
//            transform: translate3d(0, 5px, 0);
//  }
//
//  100% {
//    -webkit-transform: none;
//            transform: none;
//  }
//}
//
//@-webkit-keyframes fadeInDown {
//  0% {
//    opacity: 0;
//    -webkit-transform: translate3d(0, -100%, 0);
//            transform: translate3d(0, -100%, 0);
//  }
//
//  100% {
//    opacity: 1;
//    -webkit-transform: none;
//            transform: none;
//  }
//}
//
//@keyframes fadeInDown {
//  0% {
//    opacity: 0;
//    -webkit-transform: translate3d(0, -100%, 0);
//            transform: translate3d(0, -100%, 0);
//  }
//
//  100% {
//    opacity: 1;
//    -webkit-transform: none;
//            transform: none;
//  }
//}
//
//
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

/*
@-webkit-keyframes fadeInArrow {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: translateX(-50%) scale(1.5);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: translateX(-50%) scale(1);
    }
}

@keyframes fadeInArrow {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: translateX(-50%) scale(1.5);
    }
    50%{
        transform: translateX(-50%) scale(0.5);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: translateX(-50%) scale(1);
    }
    from, 20%, 53%, 80%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transform: translate3d(0,0,0) translateX(-50%) ;
      }

      40%, 43% {
        animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -30px, 0) translateX(-50%) ;
      }

      70% {
        animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transform: translate3d(0, -15px, 0) translateX(-50%) ;
      }

      90% {
        transform: translate3d(0,-4px,0) translateX(-50%) ;
      }
}
*/

//
//@keyframes bounceInUp {
//  from, 60%, 75%, 90%, to {
//    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
//  }
//
//  from {
//    opacity: 0;
//    transform: translate3d(0, 3000px, 0);
//  }
//
//  60% {
//    opacity: 1;
//    transform: translate3d(0, -20px, 0);
//  }
//
//  75% {
//    transform: translate3d(0, 10px, 0);
//  }
//
//  90% {
//    transform: translate3d(0, -5px, 0);
//  }
//
//  to {
//    transform: translate3d(0, 0, 0);
//  }
//}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
        display: block;
    }
    100% {
        opacity: 0;
        visibility: hidden;
        display: none;
    }
}

/*
@keyframes ZoomOut {
    0% {
        opacity: 0;
        transform: scale(1.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}


@keyframes fadeInOut {
    0% {
        opacity: 1;
        visibility: visible;
    }
    70%{
        opacity: 1;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes grow {
    0% {
        transform: scale(1);
    }
    50%{
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes fadeSemiIn {
    0% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

@keyframes fadeSemiIn {
    0% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

@-webkit-keyframes blurIn {
    0% {
        filter: blur(50px);
    }
    100% {
        filter: blur(0px);
    }
}

@keyframes blurIn {
    0% {
        filter: blur(50px);
    }
    100% {
        filter: blur(0px);
    }
}
*/

/*@-webkit-keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}*/



//.animation-example{
//    animation: pulse;
//    animation-duration: 0.5s;
//    animation-timing-function: ease-out;
//    animation-delay: 0s;
//    animation-fill-mode: forwards;    
//} 