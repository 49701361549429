/********************************************************************************* PROFILE */

/*  index

    ////// COMMONS
    ////// PROFILE DATA
    ////// EVOLUTION

*/

/*
    ////// COMMONS
*/



/*
    ////// PROFILE DATA
*/
.profile-wrapper{
    @include flex-container;
}
.profile-information{
    @include flex-fixed-element(30%);

    .field-block{
        margin: 0 0 20px 0;
    }
    input[type="text"], input[type="email"], input[type="password"]{
        border: none;
        border-bottom: 1px solid $color-third;
        padding: 5px 0;
        background: none;
    }
    label{
        font-weight: $bold;
        color: $color-third
    }
    .checkbox-reverse{
        @include flex-container;
        align-items: flex-start;

        label{
            order: 2;
            @include flex-flexible-element;
        }
        input{
            order: 1;
            @include flex-fixed-element(20px);
            margin-top: 5px;
        }
    }


    @media screen and (max-width: 1500px){
        @include flex-fixed-element(40%);
    }
    @include bp(tablet){
        @include flex-fixed-element(100%);
        margin: 0 0 30px 0;
    }
}
.profile-data{
    background: #fff;
    padding: 30px;
    border-radius: 50px;
}

.profile-card{
    @include flex-container;
    margin: 0 0 20px 0;

    h1{
        @include font-size(2.4);
        color: $color-third;
        text-transform: uppercase;
    }
}
.image-upload{
    @include flex-fixed-element(170px);

    .upload-box{
        display: none;
    }
    @include bp(mobile){
        @include flex-fixed-element(100px);
    }
}
.profile-image{
    width: 170px;
    height: 150px;
    border: 1px solid $color-light-gray;
    margin: -50px 0 0 -50px;
    background: #fff;
    position: relative;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @include bp(mobile){
        width: 130px;
    }
    .edit_image{
        position: absolute;
        bottom: 5px;
        left: 5px;
        background: $color-orange;
        border: none;
        @include font-size(1.2);
        color: #fff;
        padding: 5px 10px;

        border-radius: 7px;
    }
}

.genre{
    @include flex-flexible-element;

    li{
        input[type="radio"]{
            position: absolute;
            opacity: 0;
            z-index: 1;


            & + label{
                position: relative;
                z-index: 10;
                @include font-size(1.4);
                font-weight: $bold;
                color: $color-third;
                opacity: 0.3;
            }
            
            &:checked{
                & + label{
                    opacity: 1;
                }
            }
        }
        input[value="male"]{
            & + label{
                background: url("../img/icon-male.svg") no-repeat left center;
                padding: 10px 0 0 30px;
            }
        }
        input[value="female"]{
            & + label{
                background: url("../img/icon-female.svg") no-repeat left center;
                padding: 10px 0 0 30px;
            }
        }
    }
}
.personal-password{
    padding: 40px;
}


/*
    ////// EVOLUTION
*/
.profile-graphics{
    @include flex-flexible-element;
    background: #fff;
    padding: 40px;
    border-radius: 50px;
    margin: 0 0 0 50px;

    h1{
        @include font-size(3);
        text-transform: uppercase;
    }
    h2{
        @include font-size(2);
        text-transform: uppercase;
    }
    .statistics-block{
        margin: 20px 0 40px 0;

        .statistics-menu{
            @include font-size(1.2);
            text-align: right;

            span{
                color: $color-orange;
                margin: 0 0 0 20px;

                &:hover{
                    cursor: pointer;
                }
                &.current-statistics-menu{
                    font-weight: $bold;
                }
            }
        }
    }

    @include bp(tablet){
        @include flex-fixed-element(100%);
        margin: 0;

    }
}