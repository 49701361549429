/********************************************************************************* BRANCAM */

/*  index

    ////// COMMONS
    ////// BREADCRUMB
    ////// LANGUAGE MENU
    ////// SLIDER
    ////// TEXT I FOTOS
    ////// NEWS
    ////// PAGINATOR
    ////// FORM
    ////// NEWSLETTER
    ////// SG STYLES
    ////// COOKIES
    ////// LSIT OF SUBPAGES

*/


/*
    ////// COMMONS
*/
.brcm-body{
    @extend %font-family-secondary;

    a{
        color: $color-orange;
        text-decoration: underline;
    }
    h1, h2{
        @extend %font-family-main;
    }
}
.brcm-pagina-element{
    padding: 0 0 40px 0;
}

.main-content, .main-wrapper{
    .brcm-list-of-attachments{
        li{
            margin: 0 0 3px 0;
        }
    }
    .brcm-attachment.brcm-pdf{
        a{
            background: url("../img/icon-clip.svg") no-repeat left 4px;
            padding-left: 15px;
            @include color-link($color-main);
            font-weight: $bold;
        }
        .brcm-size{
            margin: 0 0 0 5px;
            font-style: italic;
        }
    }
}


/*
    ////// BREADCRUMB
*/
.brcm-breadcrumb{
    @include font-size(1.2);
    margin: 0 0 30px 0;
    font-style: italic;
    background: url(../img/icon-home.svg) no-repeat left center;
    padding-left: 20px;
    
    a{
        color: $color-main;
        text-decoration: underline;
    }
}


/*
    ////// LANGUAGE MENU
*/
.language-menu{
    text-align: right;
    color: white;
    
    span, a{
        display: inline-block;
        @include font-size(1.4);
        margin: 0;
        position: relative;
        padding: 0 5px;
        
        &:after{
            content: "|";
            position: absolute;
            right: -5px;
            top: 0px;
        }
        &:last-child{
            &:after{
                content: "";
            }
        }
    }
    span{
        position: relative;
        
        &:before{
            content: "";
            position: absolute;
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
            width: 5px;
            height: 5px;
            background: white;
            border-radius: 100px;
        }
    }
    a{
        color: white;
        position: relative;
        
        &:hover{
            &:before{
                content: "";
                position: absolute;
                top: -5px;
                left: 50%;
                transform: translateX(-50%);
                width: 5px;
                height: 5px;
                background: white;
                border-radius: 100px;
            }
        }
    }
    
}

/*
    ////// SLIDER
*/
.slide-home, .main-content, .photos{
    //margin-bottom: 50px;

    .brcm-flexslider{
        margin: 0;
        background: none;
        border: none;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;

        .brcm-flexslider-direction-nav a{
            &.brcm-flexslider-prev{
                background: url(../img/arrow-left-slide.svg) no-repeat center center;
                width: 50px;
                height: 50px;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
            &.brcm-flexslider-next{
                background: url(../img/arrow-right-slide.svg) no-repeat center center;
                width: 50px;
                height: 50px;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .brcm-flexslider-control-paging li a{
            background: $color-main;

            &.brcm-flexslider-active{
                background: $color-secondary;
            }
        }
        .brcm-flexslider-pauseplay{
            a{
                display: block;
                padding: 0 0 0 30px;
                width: 16px;
                height: 25px;

                &.brcm-flexslider-play{
                    background: url(../img/icon-play.svg) no-repeat left center;
                }
                &.brcm-flexslider-pause{
                    background: url(../img/icon-pause.svg) no-repeat left center;
                }

            }
        }
        .brcm-flexslider-slides li{
            position: relative;
            margin: 0;
            height: 600px;
            overflow: hidden;

            img{
                width: 100%;
                max-width: 100%;
            }
        }
        .brcm-flexslider-caption{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            padding: 10px 20px;
            text-shadow: 0 1px 0 rgba($color-main,0.5);
            background: rgba($color-main,0.7);

            h2{
                color: white;
                margin: 0 0 2px 0;
                width: auto;
                @include font-size(2.4);
            }
            h3{
                color: white;
                margin: 0 0 2px 0;
                width: auto;
                @include font-size(2);
            }
            p{
                color: white;
                margin: 0 0 10px 0;
                width: auto;

                &.withlink{
                    background: none;
                    padding: 0;
                }
            }
            a{
                @extend .button;
                background: $color-secondary;
                @include color-link(white);

                &:hover{
                    background: darken($color-secondary,10%);
                }
            }
            &:empty{
                display: none;
            }
        }
    }
}

/*
    ////// TEXT I FOTOS
*/
%default-list-item{
    background: url(../img/icon-list.png) no-repeat left 8px;
    padding: 1px 0 1px 15px;
    margin: 0 0 5px 0;
}
.main-content, .main-wrapper{
    .brcm-generic-element-with-images-on-the-right{
        margin: 0 0 40px 0;
        
        .brcm-body{
            
            ul li{
                @extend %default-list-item;
            }
            em{
                font-style: italic;
            }
        }
        .brcm-group-of-images{
            display: inline-block;
            vertical-align: top;
            width: 30%;
            margin: 0 0 0 20px;
            float: right;

            @include bp(desktop){
                float: none;
                margin: 0 0 20px 0;
                display: block;
                width: 100%;
            }
            
            img{
                width: 100%;
            }
            .brcm-caption{
                width: 100%!important;
                text-align: center;
                @include font-size(1.2);
                line-height: 18px;
                line-height: 1.8rem;

                a{
                    @include color-link($color-main);
                }
            }
            
            & + .brcm-body{
                display: inline-block;
                vertical-align: top;
                width: 65%;
                margin: 0 15px 0 0;

                @include bp(desktop){
                    float: none;
                    margin: 0 0 20px 0;
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    .brcm-generic-element-with-images-on-the-left{
        margin: 0 0 40px 0;
        
        .brcm-body{
            
            ul li{
                @extend %default-list-item;
            }
            em{
                font-style: italic;
            }
        }
        .brcm-group-of-images{
            display: inline-block;
            vertical-align: top;
            width: 30%;
            margin: 0 20px 0 0;

            @include bp(desktop){
                float: none;
                margin: 0 0 20px 0;
                display: block;
                width: 100%;
            }
            
            img{
                width: 100%;
            }
            .brcm-caption{
                width: 100%!important;
                text-align: center;
                @include font-size(1.2);
                line-height: 18px;
                line-height: 1.8rem;

                a{
                    @include color-link($color-main);
                }
            }
            & + .brcm-body{
                display: inline-block;
                vertical-align: top;
                width: 65%;
                margin: 0 0 0 15px;

                @include bp(desktop){
                    float: none;
                    margin: 0 0 20px 0;
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    .brcm-generic-element-with-image-gallery{
        margin: 0 0 40px 0;
        
        .brcm-body{
            
            ul li{
                @extend %default-list-item;
            }
            em{
                font-style: italic;
            }
        }
        .brcm-group-of-images{
            //@include flex-container;
            //justify-content: flex-start;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 30px;
            
            .brcm-image-and-caption{
                //@include flex-fixed-element(18%);
                float: none;
                margin: 0;
            }
            img{
                width: 100%;
            }
            @include bp(desktop){
                grid-template-columns: repeat(3, 1fr);
            }
            @include bp(mobile){
                grid-template-columns: repeat(2, 1fr);
            }
        }
        .brcm-caption{
            width: 100%!important;
            text-align: center;
            @include font-size(1.2);
            line-height: 18px;
            line-height: 1.8rem;

            a{
                @include color-link($color-main);
            }
        }
        .brcm-image{
            height: 180px;
            overflow: hidden;
            margin: 0 0 10px 0;

            a{
                display: block;
                height: 100%;
            }

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .brcm-clear{
            display: none;
        }
    }
    .brcm-generic-element-with-big-images{
        margin: 0 0 40px 0;
        
        .brcm-body{
            
            ul li{
                @extend %default-list-item;
            }
            em{
                font-style: italic;
            }
        }
        .brcm-group-of-images{

            img{
                max-width: 100%;
            }
        }
        .brcm-caption{
            width: 100%!important;
            text-align: center;
            @include font-size(1.2);
            line-height: 18px;
            line-height: 1.8rem;

            a{
                @include color-link($color-main);
            }
        }
    }
    .brcm-gallery-of-images{
        //@include flex-container;
        //justify-content: flex-start;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 30px;
        margin: 0 0 20px 0;
            
        .brcm-image-and-caption{
            //@include flex-fixed-element(18%);
            float: none;
            
            img{
                width: 100%;
            }
        }
        .brcm-image{
            height: 180px;
            overflow: hidden;
            margin: 0 0 10px 0;

            a{
                display: block;
                height: 100%;
            }

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .brcm-caption{
            width: 100%!important;
            text-align: center;
            @include font-size(1.2);
            line-height: 18px;
            line-height: 1.8rem;

            a{
                @include color-link($color-main);
            }
        }
        @include bp(desktop){
            grid-template-columns: repeat(3, 1fr);
        }
        @include bp(mobile){
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .brcm-list-of-attachments{
        .brcm-description{
            @include font-size(1.4);
        }
    }
    
    .brcm-list-of-events, .brcm-events-main-wrapper .brcm-events-list{
        ol{
            @include flex-container;
        }
        li{
            @include flex-fixed-element(100%);
            list-style-type: none;
        }
        .brcm-date-especial{
            background: $color-secondary;
            border: none;
        }
        .brcm-date, .brcm-location, .brcm-organizer{
            @include font-size(1.4);
        }
        .brcm-title{
            @include font-size(1.8);

            a{
                @include color-link($color-secondary);
            }
        }
        .brcm-image{
            width: 100%;
            height: 120px;
            overflow: hidden;

            a{
                height: 100%;
                width: 100%;
                display: block;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
div[style^="clear"]{
    display: none;
}
.main-content, .main-wrapper{
    .brcm-body{
        h2{
            margin: 0 0 15px 0;
        }
        ol{
            margin: 20px 0;

            li{
                margin: 0 0 5px 0;
            }
        }
    }
}


/*
    ////// NEWS
*/

.main-content .brcm-list-of-news, .last-news .brcm-list-of-news{
    ol{
        //@include flex-container;
        display: grid;
        height: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 30px;
        row-gap: 40px;
    }
    li{
        //@include flex-fixed-element(31%);
        list-style-type: none;
    }
    .brcm-image-and-caption{
        width: 100%;
        margin: 0 0 10px 0;    
    }
    .brcm-image{
        width: 100%;
        height: 200px;
        overflow: hidden;
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .brcm-date{
        @include font-size(1.4);
        font-style: italic;
        color: $color-dark-gray;
    }
    .brcm-title{
        @include font-size(1.8);
        
        a{
            @include color-link($color-main);
            @include font-size(2);
            font-weight: $bold;
            transition: all 0.2s ease-in-out;
            
            &:hover{
                color: $color-green;
            }
        }
    }
    .brcm-summary{
        @include font-size(1.4);
    }
    .brcm-category{
        display: none;
    }
}
.brcm-news-detail{
    .brcm-group-of-images{
        width: 40%;
        
        .brcm-image-and-caption, img{
            max-width: 100%;
        }
    }
    .brcm-caption{
        width: 100%!important;
        @include font-size(1.2);
        line-height: 18px;
        line-height: 1.8rem;
    }
}


/*
    ////// PAGINATOR
*/
.brcm-pagination, .product-page .brcm-products-module .brcm-list-of-products .brcm-pagination{
    @include flex-container;
    border-top: 3px solid $color-secondary;
    margin: 30px 0;
    padding: 10px 0;
    
    .brcm-next, .brcm-previous, .brcm-current{
        @include flex-flexible-element;    
    }
    .brcm-previous{
        position: relative;
        padding: 0 0 0 25px;
        
        &:before{
            content: "";
            background: url(../img/arrow-left.svg) no-repeat center center;
            height: 25px;
            width: 25px;
            left: 0;
            top: 0;
            position: absolute;
        }
        span{
            display: none;
        }
    }
    .brcm-next{
        text-align: right;
        position: relative;
        padding: 0 25px 0 0;
        
        &:after{
            content: "";
            background: url(../img/arrow-right.svg) no-repeat center center;
            height: 25px;
            width: 25px;
            right: 0;
            top: 0;
            position: absolute;
        }
        span{
            display: none;
        }
    }
    .brcm-previous + .brcm-current{
        text-align: center;    
    }
}

/*
    ////// FORM
*/

input[readonly]{
    background: $color-light-gray;
    color: darken($color-light-gray,40%);
} 
input[maxlength="2"], input[maxlength="4"], input[maxlength="10"]{
    display: inline-block;
    text-align: center;
}
input[maxlength="2"]{
    width: 50px;
}
input[maxlength="4"]{
    width: 60px;
}
input[maxlength="10"]{
    width: 130px;
}
.main-content .brcm-form{
    a{
        @include color-link($color-orange);
    }
    .brcm-field{
        margin: 5px 0;
        margin-top: 10px;
    }
}
.main-content .brcm-form{
    p{
        @include font-size(1.4);
    }
    .brcm-field .brcm-info{
        color: $color-secondary!important;
        font-style: italic;
    }
}

/*
    ////// NEWSLETTER
*/
#brcm-newsletter-add{
    h2, .brcm-invitation{
        display: none;    
    }
    label[for="brcm-e-mail-subscriber"]{
        display: none;
    }
    input[type="text"]{
        color: $color-main;
    }
    .brcm-fields, .brcm-button{
        display: inline-block;
        vertical-align: top;
    }
}


/*
    ////// SG STYLES
*/


/*
    ////// COOKIES
*/
.brcm-cookies-message{
    max-width: none!important;
    padding: 7px 10px!important;
    background: rgba(white,0.8);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;
    box-shadow: 0 1px 15px 1px rgba($color-main,0.4);
    
    p{
        @include font-size(1.4);
    }
    
    .brcm-close-cookies-message{
        padding: 5px 20px;
        background: $color-secondary;
    }
}



/*
    ////// LSIT OF SUBPAGES
*/
.brcm-list-of-subpages{
    li{
        margin: 0;

        a{
            background: url("../img/arrow-menu-selected.svg") no-repeat left center;
            background-size: 8px;
            padding-left: 20px;

            &:hover{
                text-decoration: underline;
            }
        }
    }
}

/*
    ////// NEWSLETTER BR
*/
.brcm-newsletter-module{
    h2#brcm-feeds{
        display: none;

        & + ul{
            display: none;
        }
    }
    .brcm-errors{
        @include font-size(1.2);
    }
    .brcm-field{
        &:nth-child(3){
            display: none;
        }
    }
}
