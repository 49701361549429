/********************************************************************************* BREADCRUMB */

/*  index

    ////// BREADCRUMB
*/


/*
    ////// BREADCRUMB
*/
.breadcrumb{
    @include font-size(1.2);
    font-weight: $regular;
    margin: 0 0 30px 0;
    font-style: italic;

    .breadcrumb-separator{
        padding: 5px 0;
    }
    .current-page{
        padding: 5px;
    }

    a, span{
        display: inline-block;
        vertical-align: middle;
        padding: 5px;
    }
    a{
        @include color-link($color-secondary);
        &:hover, &:active{
            text-decoration: underline;
        }
    }
    .breadcrumb-home{
        background: url("../img/icon-home.svg") no-repeat left center;
        width: 20px;
        height: 20px;
    }
    @media screen and (max-width: $wrapper){
        padding: 0 10px;
    }
}