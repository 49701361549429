/********************************************************************************* HEADER */

/*  index

    ////// TOP MENU
    ////// TOP ACTIONS
    ////// MOBILE MENU
*/

/*
    ////// TOP MENU
*/
.top-menu{
    @include flex-container;
    align-items: center;
    height: auto;
    padding: 20px 50px;
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;

    @include bp(tablet){
        padding: 20px 10px;
    }
}

.logo{

    a{
        display: block;
    }
    @include bp(tablet){
        @include flex-fixed-element(90px);
        margin: 0 10px 0 0;
    }
    @include bp(minimobile){
        @include flex-fixed-element(60px);
    }
}
.registered-top-info{
    ul{
        @include flex-container;
    }
    li{
        @include font-size(2);
        margin: 0 20px 0 0;
        font-weight: $bold;
        text-transform: uppercase;
    }
    .user-points-title, .user-classification-title{
        margin: 0 10px 0 0;
    }
    .user-points, .user-global-classification{
        color: $color-orange;
    }
    @include bp(desktop){
        display: none;
    }
}

/*
    ////// TOP ACTIONS
*/
.public-menu{
    .top-actions{

        li{
            @include font-size(1.8);
            margin: 0 5px;
            display: inline-block;
            vertical-align: top;
            text-transform: uppercase;

            a{
                display: block;
                padding: 10px 40px;
                border-radius: 20px;
            }

            &.top-action-rates{
                a{
                    background: $color-brown;
                }

                @include bp(desktop){
                    display: none;
                }
            }
            &.top-action-register{
                a{
                    background: $color-orange;
                    @include color-link(#fff);

                    @include bp(mobile){
                        padding: 10px 15px;
                    }
                }
                @include bp(mobile){
                    @include font-size(1.6);
                }
                /*@include bp(desktop){
                    display: none;
                }*/
            }
            &.top-action-login{
                a{
                    background: $color-main;
                    @include color-link(#fff);
                }
                @include bp(desktop){
                    display: none;
                }
            }
        }
    }
}

.public.access-page{
    .top-actions{

        li{
            &.top-action-rates, &.top-action-register, &.top-action-login, &.trigger-mobile-menu{
                display: none;
            }
        }
    }
}

.registered-menu{
    .top-actions{

        li{
            display: inline-block;
            vertical-align: top;
            position: relative;
        }

        .top-action-subscribe{
            a{
                background: url("../img/premium-star-current.svg") no-repeat 10px center $color-orange;
                color: #fff;
                @include font-size(1.8);
                padding: 10px 20px 10px 40px;
                border-radius: 15px;
                text-align: center;
                text-transform: uppercase;
                display: block;

                @include bp(mobile){
                    @include font-size(1.4);
                    //background-position: center center;
                    height: 44px;
                    //padding: 10px 20px;
                }
            }

            .desktop-v{
                @include bp(mobile){
                    display: none;
                }
            }
            /*.mobile-v{
                display: none;
            }*/
        }
        .top-action-user-account-menu{
            .open-submenu{
                background: url("../img/icon-user.svg") no-repeat left 10px center $color-main;
                color: #fff;
                @include font-size(1.8);
                padding: 10px 20px 10px 40px;
                border-radius: 15px;
                text-align: center;
                text-transform: uppercase;
                position: relative;
                z-index: 100000;
                display: block;

                @include bp(mobile){
                    @include font-size(1.4);
                    height: 44px;
                    background-position: center center;
                    padding: 10px 20px;
                }
            }

            .desktop-v{
                @include bp(mobile){
                    display: none;
                }
            }
            .mobile-v{
                display: none;

                /*@include bp(mobile){
                    display: block;
                }*/
            }
        }
        .top-action-prizes{
            a{
                //background: url("../img/circle.svg") no-repeat left 10px center $color-orange;
                background: $color-orange;
                color: #fff;
                @include font-size(1.8);
                padding: 10px 40px;
                border-radius: 15px;
                text-align: center;
                text-transform: uppercase;
                display: block;

                @include bp(mobile){
                    @include font-size(1.6);
                }
            }

            @include bp(desktop){
                display: none;
            }
        }
        .top-action-user-account-submenu{
            display: none;
            position: absolute;
            z-index: 10000;
            background: #fff;
            border-radius: 20px;
            padding: 15px 10px;
            width: 230px;
            right: 0;
            top: 100%;
            border: 1px solid $color-brown;

            li{
                margin: 0;
                display: block;

                a{
                    display: block;
                    padding: 3px 10px;
                    @include color-link($color-main);

                    &:hover{
                        color: $color-orange;
                    }
                }
            }
        }
        .desktop-v{
            @include bp(mobile){
                display: none;
            }
        }
        .mobile-v{
            display: none;

            @include bp(mobile){
                display: block;
            }
        }
        @include bp(tablet){
            @include flex-flexible-element;
            text-align: right;
        }
    }
}



/*
    ////// MOBILE MENU
*/
.public-menu .top-actions, .registered-menu .top-actions{
    .trigger-mobile-menu{
        display: none;

        a{
            display: block;
            background: url("../img/icon-menu.svg") no-repeat center center;
            width: 34px;
            height: 27px;
            margin: 10px 0 0 10px;

            @include bp(mobile){
                margin: 0 0 0 10px;
            }
        }

        @include bp(desktop){
            display: inline-block;
        }
    }
}

.public-menu{
    .top-actions .trigger-mobile-menu{
        a{
            padding: 22px;
        }
    }
}
.mobile-public-menu{
    display: none;
    background: $color-main;
    position: absolute;
    top: 75px;
    right: 10px;
    width: 200px;
    padding: 10px;

    li{
        display: block;
        text-align: center;
    }

    a{
        @include color-link(#fff);
        display: block;
        padding: 10px 20px;

        &:hover{
            background: $color-orange;
        }
    }
}