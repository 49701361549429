/********************************************************************************* COLORS */

/*  index

    ////// DESIGN COLORS
    ////// BASIC COLORS
    ////// BUTTONS
    ////// TABLES
*/

/*
    ////// DESIGN COLORS
*/
$color-main: 					#333232;
$color-secondary: 				#EAEAEA;
$color-third:                   #252628;
$color-fourth:                  #BAECED;
$color-orange: 					#FF4A1A;
$color-ictiva-shop:             #4baadc;
$color-brown:					#E1E2DC;
$color-red-orange:              #DB3939;


/*
    ////// BASIC COLORS
*/
$color-green:                   #00C479;
$color-red: 					#FF2424;
$color-dark-gray:               #515151;
$color-light-gray:              #878787;
$color-super-light-gray:        #B5B5B5;



/*
    ////// BUTTONS
*/
$color-main-button:             #116A72;
$color-main-button-text:        #fff;
$color-main-button-text-hover:  #fff;

/*
    ////// TABLES
*/
$color-table-header: 			#FBFBFB;